.cc_container{
    /* background-color: gold; */
    padding: 40px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
}
.cc_container .cc_div1{
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
    width: 50%;
}
.cc_container .cc_content_container{
    border: 1px solid black;
    border-radius: 10px;
    padding: 40px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.cc_container .cc_content_container .cc_heading{
    font-weight: 600;
    color: black;
    font-size: 20px;
}
.cc_container .cc_content_container .cc_desc{
    margin-top: 10px;
    font-weight: 500;
    color: black;
}
.cc_container .cc_content_container .cc_sec1{
    position: relative;
    margin-top: 30px;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
}
.cc_container .cc_content_container .cc_sec1 .cc_left_sec{
    /* background-color: pink; */
    width: 62%;
}
.cc_container .cc_content_container .cc_sec1 .cc_right_sec{
    /* background-color: black; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32%;
    /* height: 420px; */
    border: 1px solid black;
    border-radius: 10px;
    overflow: hidden;
    padding: 0 20px;
}
.cc_container .cc_content_container .cc_sec1 .cc_right_sec .cc_right_sec_inner_div{
    /* background-color: black; */
    /* display: flex; */
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px 20px;
    text-align: center;
}
.cc_container .cc_content_container .cc_sec1 .cc_right_sec .cc_right_sec_inner_div .cc_inner_text{
    font-size: 14px;
    font-weight: 500;
    /* color: brown; */
}
.cc_container .cc_content_container .cc_sec1 .cc_right_sec .cc_img{
    height: 100%;
    width: 100%;
}

.cc_container .cc_content_container .cc_next_btn{
    all:unset;
    margin-top: 80px;
    padding: 8px 20px;
    border: 1px solid black;
    border-radius: 5px;
    align-self: center;
    cursor: pointer;
    color: black;
}

/* .cc_container .cc_content_container .cc_next_btn:hover{
    background-color: #646464;
    color: white;
} */

.cc_bottoms{
    margin-top: 20px;
    align-self: flex-end;
    
}
.cc_bottoms .cc_btn{
    all:unset;
    /* border: 1px solid black; */
    color: black;
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 14px;
}
.cc_bottoms .cc_btn3{
    margin-right: 0px;
}