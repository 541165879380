

.invoice-popup{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    justify-content: center;
    align-items: center;
    background:rgba(0,0,0,0.3) ;
    z-index: 99999;
}
.invoice-inner{
    padding: 0px 0px;
    background: #fff;
    border-radius: 16px;
    display: inline-block;
    margin: 1rem;
    position: relative;
    max-width: 100%;
    width: 90%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    justify-self: center;
    /* height: 100vh; */
}
/* popup inner design */
.invoice-btn-close {
    width: 40px;
    float: right;
    text-align: center;
    margin-top: 8px;
    cursor: pointer;
}
.invoice-b1 {
    width: 100%;
    float: left;
}
.invoice-p-left {
    width: 50%;
    float: left;
    padding-bottom: 10px;
}
.date-p-box {
    width: 100%;
    float: left;
}
.invoice-p-right {
    width: 50%;
    float: left;
    padding-bottom: 10px;
}
.date-p-left {
    width: 88%;
    float: left;
    text-align: right;
    padding-right: 10px;
}
.date-p-right {
    width: 20px;
    float: left;
}
button.invoice-p-btn {
    background: #F5843E;
    border: 0px;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    margin-top: -10px;
    cursor: pointer;
}
button.invoice-p-btn i {
    color: #fff;
    font-size: 16px;
}
.popup-list-b {
    width: 100%;
    float: left;
}
.popup-list-b table.invoces-table {
    width: 100%;
    float: left;
}
.invoice-box.padding {
    padding: 15px;
}
.pop-total {
    width: 100%;
    float: left;
    text-align: right;
    padding-top: 15px;
}
.pop-tol-b {
    width: 76.5%;
    float: left;
    font-weight:bold;
}
button.send-pop-bt {
    background: #F5843E;
    border: 0px;
    color: rgba(255,255,255,1);
    padding: 10px 20px !important;
    border-radius: 8px !important;
    z-index: 1 !important;
    font-size: 16px;
    transition: all 0.5s;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}
/* button.send-pop-bt:hover {
    background: rgba(107, 107, 107, 0.4);
    border: solid 1px #f6853f;
} */
.pop-send-btn {
    width: 100%;
    float: left;
    text-align: right;
    margin-top: 20px;
}


/* Invoice popup */

.pdf-inner {
    width: 100%;
    float: left;
}
.pdf-inner {
    /* width: 820px; */
    /* float: left; */
    /* height: 1189px; */
    width: 100%;
    float: left;
    clear: both;
}
.pdf-inner-box {
    width: 100%;
    float: left;
    padding: 20px 30px;
}
.padf-invoice {
    width: 100%;
    float: left;
}
.pdf-in-box {
    width: 200px;
    float: right;
}
.pdf-in-head {
    width: 50%;
    float: left;
    padding-top: 6px;
}
.pdf-log {
    /* background: #1a2b57; */
    float: right;
}
.pdf-log img {
    width: 75px;
}
.pdf-in-head p {
    color: #000;
    font-size: 18px;
    padding-top: 6px;
}
.pdf-in-head h2 {
    color: #000000;
    font-size: 26px;
}
.pdf-info-box {
    width: 100%;
    float: left;
    padding-bottom: 10px;
}
.padf-info-1 {
    width: 110px;
    float: left;
    font-weight: bold;
    font-size: 16px;
    color: #000;
}
.padf-info-2 {
    font-size: 16px;
    color: #000;
}
.pdf-in-value-box {
    background: #f1f4f9;
    border-radius: 8px;
    width: 100%;
    float: left;
}
table.pdf-table {
    width: 100%;
    float: left;
    text-align: center;
    padding: 16px;
}
table.pdf-table th {
    padding-bottom: 10px;
    font-weight: normal;
    width: 20%;
}
table.pdf-table td {
    padding-bottom: 10px;
}
.pdf-in-value-box-2 {
    background: #f1f4f9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 16px;
    width: 100%;
    float: left;
    margin-top: 30px;
}
table.pdf-table-2 {
    width: 100%;
    text-align: center;
    float: left;
}
table.pdf-table-2 td {
    width: 20%;
}
.pdf-in-value-box-3 {
    background: #e3ecf3;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 8px;
    padding: 16px;
    width: 100%;
    float: left;
}
.invoice-pdf-btn {
    width: 100%;

    clear: both;
    text-align: center;
}
button.pdf-btn-in {
    background: #F5843E;
    border: solid 2px #F5843E;
    color: #fff;
    padding: 4px 25px;
    border-radius: 8px !important;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-weight: bold;
    font-size: 16px;
}
button.pdf-btn-in:hover {
    color: #fff;
    background: #F5843E;
}
.padf-invoice-bo {
    width: 100%;
    float: left;
    clear: both;
}
.padf-invoice.margin {
    margin-top: 30px;
}
button.pdf-btn-in span {
    margin-top: 4px;
    float: left;
    padding-left: 10px;
}
button.pdf-btn-in 
 i.material-icons {
    float: left;
}
.pdf-btn-pop {
    text-align: center;
    margin-bottom: 40px;
}
section.pdf-toolbar {
    text-align: center;
    padding: 30px 0px;
    position: absolute;
    bottom: 0px;
    z-index: 9999999;
    width: 100%;
}
section.pdf-body {
    padding-bottom: 83px;
    display: flex;
    width: 100% !important;
}
.pdf-box.pdf-121 {
    width: 100% !important;
}
button.pdf-btn-in i.material-icons {
    width: 23px;
}