
.cards-box {
    width: 100%;
    float: left;
    padding: 0px !important;
    margin-bottom: 15px;
    border-radius: 9px;
    border: 0px !important;
    background: #fff;
    box-shadow: 3px 5px 10px #707070;
}
.cards-inn-box {
    width: 80px;
    height: 80px;
    /* float: left; */
    border: 0px !important;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 20px;
    border-radius: 4px;
    color: #fff !important;
    margin: 0px auto;
}
.cards-col-mid {
    width: 100% !important;
    float: left !important;
    text-align: left;
}
.cards-inn-box img{
    width: 80px !important;
    /* height: 70px !important; */
}
.cards-btn-get {
    width: 48%;
    float: left;
    text-align: right;
    margin-left: 20px;
}
.cards-col-mid p {
    font-size: 14px;
    width: 100%;
    float: left;
    margin-bottom: 0px;
    padding-bottom: 5px;
    color: #43003E;

}
.card-collapse-btn-info {
    font-size: 16px !important;
    cursor: pointer;
}
.card-collapse {
    font-size: 14px;
    padding-top: 5px;
    width: 78%;
    float: right;
    text-align: left;
}
.cards-btn-get .card-btn-1 {
    font-size: 14px;
    background: #F5843E;
    color: #fff;
    padding: 4px;
    border-radius: 4px;
    border: solid 1px #F5843E;
    font-weight: bold;
}
/* .cards-btn-get .card-btn-1:hover {
    background: #eee;
    color: #f6853f;
    text-decoration: none;
} */
.button-curve {
    /* width: 50%; */
    float: left;
   
}
.btn-curve-box span {
    font-weight: bold;
    padding: 4px 10px;
    width: 90px;
    text-align: center;
}
.btn-curve-box {
    background: rgba(245, 132, 61, 0.09);
    border-radius: 30px;
    display: flex;
    font-size: 16px;
    color: #E8742D;
}

.btn-curve-box i.material-icons {
    color: #fff;
    font-size: 18px;
    margin-right: 5px;
}
.card-collapse-btn-info-b .material-icons {
    font-size: 18px;
    color: #AB3495;
}
.redeem-card-inn {
    padding: 15px 15px;
    width: 100%;
    float: left;
    border-radius: 9px;
    border: solid 1px #ccc !important;
  
}
h2.business-name-head {
    font-size: 18px;
    color: #1F1F1F;
    padding-top: 10px;
    text-align: center;
}
ul.list-b-redeem {
    padding-left: 16px;
    margin-top: 5px;
    width: 100%;
    float: left;
    margin-bottom: 0px !important;
}
ul.list-b-redeem li {
    color: #43003E;
    font-size: 18px;
 
}
.text-des {
    width: 100%;
    float: left;
    text-align: left;
}
.text-des p {
    font-size: 18px;
    color: #43003E;
    margin-bottom: 18px;
    font-family: inherit !important;
    padding-top: 10px !important;
    overflow-wrap: break-word;
}
.card-btn-b-12 {
    width: 100%;
    display: flex;
    padding-top: 16px;
}
.card-btn-b-12.new-card-btn-b-12 .cards-btn-get {
    width: 76%;
}
.edit-func button.mat-crcle {
    margin-right: 10px;
}
.card-collapse-btn-info-b {
    width: 100%;
    /* border: solid 2px #AB3495; */
    font-weight: bold;
    padding: 10px 0px;
    border-radius: 8px;
    color: #AB3495;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
}
.card-collapse-btn-info-b.new-ddd {
    width: 24% !important;
}
button.redeem-pop-b {
    background: #fff;
    font-size: 16px;
    color: #AB3495;
    font-weight: bold;
    border:  2px solid #AB3495;
    width: 100%;
    padding:  10px 0px;
    border-radius: 30px;
    pointer-events: none;
}
.main-box-cpn {
    width: 100%;
}
.buttonStyle {
    background: #F5843E;
    color: #fff;
    padding: 8px 20px !important;
    border-radius: 8px !important;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    border: solid 2px #F5843E;
}
/* .buttonStyle:hover{
    background: rgba(107, 107, 107, 0.4);
} */
.f-btn {
    width: 100%;
    float: left;
    text-align: center;
}
h3.cpn-heading {
    text-align: left;
    /* padding-bottom: 20px; */
    color: #1F1F1F !important;
    font-size: 18px;
    line-height: 21px;
}
.cards-col-mid h1 {
    font-size: 50px !important;
    font-weight: bold !important;
    color: #43003E !important;
}
.card-details-b {
    width: 100%;
    float: left;
}
.card-details-b .card-collapse {
    width: 100% !important;
}
.card-details-b h2.business-name-head {
    color: #43003E !important;
}
.download-icon-box {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
}
.download-icon-box i {
    /* background: #F4F5FA; */
    padding: 4px;
    /* border-radius: 50px; */
    cursor: pointer;
    color: #0091FF;
    font-size: 24px;
}
.inner-liab-box {
    width: 100%;
    float: left;
    background: #fff;
    box-shadow: 3px 5px 10px #707070;
    border-radius: 9px;
    margin-bottom: 10px;
    border: solid 1px #ccc !important;
}
.inner-liab-wrap {
    padding: 15px;
    width: 100%;
    float: left;
}
.inner-liab-txt-box {
    display: flex;
}
.liab-text-right {
    justify-content: flex-end;
    text-align: right;
    width: 30%;
    font-size: 16px;
    line-height: 24px;
    color: #43003E;
    /* font-weight: 600; */
}
.liab-text-left {
    width: 70%;
    font-size: 16px;
    color: #43003E;
    font-weight: 600;
    line-height: 24px;
}
.inner-liab-txt-box.margin {
    margin-bottom: 10px;
}
.pdf-box{
    width: 420px;
    margin: 0px auto;
    
}
.legalese {
    background: #ebf1f7;
    width: 100%;
    float: left;
    border-radius: 8px;
    margin-top: 20px;
}
.legalese-label {
    background: #d8e5f1;
    width: 100%;
    float: left;
    border-radius: 8px;
    padding: 8px 20px;
    font-size: 12px;
    color: #43003E;
    font-weight: bold;
}
.legalese-text {
    padding: 8px 20px 16px 20px;
    font-family: 'AvenirNext-Regular';
    font-size: 12px;
    width: 100%;
    float: left;
    max-height: 76px;
    overflow: auto;
    color: #43003E;
}
.scrol-term-sec {
    width: 100%;
    float: left;
    /* max-height: 216px;
    overflow: auto; */
    /* padding-left: 5px; */
}
.legalese-label.center {
    text-align: center;
}
.cros-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
}
.cros-icon span.material-icons {
    border: solid 1px #000;
    border-radius: 50px;
}
.admin-new-card-dd {
    margin-top: 5px;
    width: 100%;
    float: left;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16PX;
}
.newtag-line {
    width: 100%;
    float: left;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    /* font-weight: bold; */
}
.link-map-line {
    width: 100%;
    float: left;
    /* margin-bottom: 16px */
}
.link-map-line a {
    font-size: 15px;
    line-height: 24px;
    color: #4989FE !important;
    text-decoration: none;
    width: 100%;
    float: left;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.new-cost-box {
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 10px;
}
.admin-new-card-dd-2 {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.box-sss-scroll {
    width: 100%;
    float: left;
    max-height: 70vh;
    overflow: auto;
    margin-top: 16px;
    padding: 2px;
}
.radio-btn-reward {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 10px;
}
.radio-inn-btn-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.cards-btn-return {
    width: 48%;
    float: left;
    text-align: center;
}
.header-new-card {
    width: 100%;
    float: left;
}
.new-head-letf-card {
    width: 65%;
    float: left;
}
.header-new-card {
    width: 100%;
    float: left;
}
.right-head-card-new {
    width: 35%;
    float: left;
}
span.downlaod-txt {
    font-size: 11px;
    color: #0091FF;
    line-height: 14px;
    font-weight: 600;
}
.new-card-award {
    width: 100%;
    float: left;
}
.bonus-box {
    width: 50%;
    justify-content: flex-end;
    float: right;
    text-align: right;
}
.bonus-box i {
    color: #BC229F;
}
span.pts-text {
    float: right;
    color: #BC229F;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding-left: 5px;
}
.card-img-center {
    width: 100%;
    float: left;
}
h1.off-txt {
    font-size: 30px !important;
    color: #BC229F !important;
    margin: 8px 0px;
    width: 100%;
    float: left;
}
.off-description {
    font-size: 15px;
    line-height: 24px;
    color: #404040;
}
.redeem-new {
    font-size: 15px;
    line-height: 24px;
    color: #404040;
    width: 100%;
    float: left;
    font-weight: bold;
}
.new-des-cards {
    width: 100%;
    float: left;
    margin-top: 16px;
    margin-bottom: 16px;
}
.list-b-redeem {
    width: 100%;
    float: left;
}
.text-des span.material-icons {
    float: left;
    font-size: 16px;
    margin-right: 4px;
    padding-top: 5px;
}
button.redeem-pop-new {
    background: #BC229F !important;
    border: solid 2px #BC229F;
    border-radius: 30px !important;
    padding: 8px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}
button.redeem-pop-new span.material-icons {
    float: left;
    font-size: 16px;
    padding-right: 10px;
    font-weight: bold;
}
button.buttonStyle.new-btn{
    padding: 8px !important;
}
.cards-btn-get button.redeem-pop-bn {
    background: transparent !important;
    border: solid 2px #BC229F;
    border-radius: 30px !important;
    font-size: 16px;
    font-weight: bold;
    color: #BC229F !important;
    padding: 8px !important;
    width: 100%;
}
.main_card_wrap {
    width: 100%;
    float: left;
    padding: 2px;
}
.design_new_card{
    width: 339px;
    margin: 0px auto;
}