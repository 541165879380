.bcq_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  box-sizing: border-box;
  flex-direction: column;
  /* background-color: gold; */
}

.bcq_container .bcq_header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: cadetblue; */
  width: 100%;
}
.bcq_container .bcq_header .bcq_btn0 {
  all: unset;
  position: absolute;
  right: 0;
  /* height: 100%;
    width: 100%; */
  /* margin-top: 40px; */
  background: #F5843E;
  border: 0px;
  color: rgba(255, 255, 255, 1);
  padding: 10px 20px !important;
  border-radius: 8px !important;
  z-index: 1 !important;
  font-size: 16px;
  transition: all 0.5s;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  cursor: pointer;
}

/* .bcq_container .bcq_header .bcq_btn0:hover {
  background: rgba(107, 107, 107, 0.4);
  border: solid 1px #f6853f;
} */

.bcq_container .bcq_inner_container {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.bcq_container .bcq_inner_container .bcq_calendar_container {
  margin-top: 60px;
}

.bcq_container .bcq_inner_container .bcq_calendar_container .bcq_table {
  border-collapse: collapse;
  width: 100%;
}

.bcq_container
  .bcq_inner_container
  .bcq_calendar_container
  .bcq_table
  .bcq_thead
  .bcq_thead_tr
  .bcq_thead_th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.bcq_container
  .bcq_inner_container
  .bcq_calendar_container
  .bcq_table
  .bcq_tbody
  .bcq_tbody_tr:nth-child(odd) {
  /* background-color: #ddd; */
}

.bcq_container
  .bcq_inner_container
  .bcq_calendar_container
  .bcq_table
  .bcq_tbody
  .bcq_tbody_tr
  .bcq_tbody_td1 {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.bcq_container
  .bcq_inner_container
  .bcq_calendar_container
  .bcq_table
  .bcq_tbody
  .bcq_tbody_tr
  .bcq_tbody_td2 {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.bcq_container .bcq_inner_container .bcq_btn_container {
  display: flex;
  margin-top: 60px;
}

.bcq_container .bcq_inner_container .bcq_btn_container .bcq_btn {
  all: unset;

  background: #F5843E;
  border: solid 2px #F5843E;
  color: #ffffff !important;
  padding: 8px 20px !important;
  border-radius: 8px !important;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

/* .bcq_container .bcq_inner_container .bcq_btn_container .bcq_btn1,
.bcq_container .bcq_inner_container .bcq_btn_container .bcq_btn3 {
  margin: 0 20px;
} */
.bcq_container .bcq_inner_container .bcq_btn_container .bcq_btn2 {
  margin: 0 20px;
}

/* .bcq_container .bcq_inner_container .bcq_btn_container .bcq_btn:hover {
  background: rgba(107, 107, 107, 0.4);
  
} */

/* my design css */
.qlist-main-heading {
  width: 600px;
  padding-bottom: 20px;
}
.qlist-main-heading-box {
  display: flex;
}
.qlist-btn-new {
  width: 16%;
  float: right;
  text-align: right;
}

.qlist-main-inn-h {
  width: 84%;
  float: left;
}

button.qlist-btn-new-inn {
  background: #F5843E;
  border: 0px;
  color: rgba(255, 255, 255, 1);
  padding: 10px 20px !important;
  border-radius: 8px !important;
  z-index: 1 !important;
  font-size: 16px;
  transition: all 0.5s;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  cursor: pointer;
}
button.qlist-btn-new-inn:hover {
  background: rgba(107, 107, 107, 0.4);
  border: solid 1px #F5843E;
}
.qlist-main-inn-h h5 {
  font-size: 18px !important;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
}
.bcq_btn_container.change-box.margin {
  width: 100%;
  margin-top: 30px;
}
.bcq_inner_container.bus-center {
  align-items: center;
}
.bus-form-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}
.b-coupon-form {
  width: 100%;
  float: left;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.bcq_btn_container.save-btn-c {
  text-align: right;
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}


.bus-form-main .li_input_container {
  width: 300px;
}