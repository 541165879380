input#formControlsTextB {
    border: 1px solid #cccccc;
    height: 48px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    padding: 10px;
    width: 300px;
    border-radius: 4px;
}
div#DateRangePickerChildren::after {
    content: "\f073";
    font-family: FontAwesome;
font-style: normal;
font-weight: normal;
text-decoration: inherit;
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 26px;
    width: 11px;
    height: 11px;
    pointer-events: none;
    color: #F5843E !important;
}
.timeSelectContainer {
    display: none !important;
}
.dateTimeLabel {
    text-align: left;
}
.fromDateHourContainer {
    padding: 5px 10px;
}
.fromDateTimeContainer input {
    padding: 0px 10px;
    border-radius: 4px;
    border: solid 1px #ccc;
}
.SelectedWeekExample .DayPicker-Month {
    border-collapse: separate;
  }
  .SelectedWeekExample .DayPicker-WeekNumber {
    outline: none;
  }
  .SelectedWeekExample .DayPicker-Day {
    outline: none;
    border: 1px solid transparent;
  }
  .SelectedWeekExample .DayPicker-Day--hoverRange {
    background-color: #EFEFEF !important;
  }

  .SelectedWeekExample .DayPicker-Day--selectedRange {
    background-color: #fff7ba !important;
    border-top-color: #FFEB3B;
    border-bottom-color: #FFEB3B;
    border-left-color: #fff7ba;
    border-right-color: #fff7ba;
  }

  .SelectedWeekExample .DayPicker-Day--selectedRangeStart {
    background-color: #FFEB3B !important;
    border-left: 1px solid #FFEB3B;
  }

  .SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
    background-color: #FFEB3B !important;
    border-right: 1px solid #FFEB3B;
  }

  .SelectedWeekExample .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
  .SelectedWeekExample .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
    border-radius: 0 !important;
    color: black !important;
  }
  .SelectedWeekExample .DayPicker-Day--hoverRange:hover {
    border-radius: 0 !important;
  }