.bl_container {
  padding: 60px 170px;
  box-sizing: border-box;
  /* background-color: gold; */
  text-align: center;
  float: left;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
}
.bl_container .bl_header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: cadetblue; */
  width: 100%;
}
.bl_container .bl_header .bl_btn0 {
  all: unset;
  position: absolute;
  right: 0;
  /* height: 100%;
    width: 100%; */
  /* margin-top: 40px; */
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 20px;
  border: 1px solid #1a2b57;
  color: #1a2b57;
  cursor: pointer;
  box-sizing: border-box;
}
.bl_container .bl_header .bl_btn0{
  background: #F5843E;
  border: 0px;
  color: rgba(255,255,255,1);
  padding: 10px 20px !important;
  border-radius: 8px !important;
  z-index: 1 !important;
  font-size: 16px;
  transition: all 0.5s;
    position: absolute;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
   cursor: pointer;
}
.bl_container .bl_header .bl_btn0::before {
  border-radius: 8px !important;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
:after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bl_container .bl_header .bl_btn0::after {
  border-radius: 8px !important;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid rgba(245,96,12,0.5);
  -webkit-transform: scale(1.2,1.2);
  transform: scale(1.2,1.2);
}
:after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* .bl_container .bl_header .bl_btn0:hover {
  background: rgba(107, 107, 107, 0.4);
  border: solid 1px #f6853f;
} */

.bl_container .bl_btn_container {
  display: flex;
  float: right;
}
.bl_container .bl_btn_container .bl_btn1 {
  margin: 0 20px;
}
.bl_container .bl_btn_container .bl_btn{
  background: #F5843E;
  border: 0px;
  color: rgba(255,255,255,1);
  padding: 10px 20px !important;
  border-radius: 8px !important;
  z-index: 1 !important;
  font-size: 16px;
  transition: all 0.5s;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
   cursor: pointer;
}
.bl_container .bl_btn_container .bl_btn::before {
  border-radius: 8px !important;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
:after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bl_container .bl_btn_container .bl_btn::after {
  border-radius: 8px !important;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid rgba(245,96,12,0.5);
  -webkit-transform: scale(1.2,1.2);
  transform: scale(1.2,1.2);
}
:after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* .bl_container .bl_btn_container .bl_btn:hover {
  background: rgba(107, 107, 107, 0.4);
  border: solid 1px #f6853f;
} */

.bl_container .bl_table_btn{
  background: #F5843E;
  border: 0px;
  color: rgba(255,255,255,1);
  padding: 10px 20px !important;
  border-radius: 8px !important;
  z-index: 1 !important;
  font-size: 16px;
  transition: all 0.5s;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
   cursor: pointer;
}
.bl_container .bl_table_btn::before {
  border-radius: 8px !important;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
:after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bl_container .bl_table_btn::after {
  border-radius: 8px !important;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid rgba(245,96,12,0.5);
  -webkit-transform: scale(1.2,1.2);
  transform: scale(1.2,1.2);
}
:after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* .bl_container .bl_table_btn:hover {
  background: rgba(107, 107, 107, 0.4);
  border: solid 1px #f6853f;
 
} */

.bl_container table th {
  background: #1a2b57;
  color: #fff ;
}
.bl-main-box-wrap {
  margin: 0px auto;
  width: 80%;
}

/* my style buttons */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .toggle {
  background-color: #F5843E;
}

input:focus + .toggle {
  box-shadow: 0 0 1px #F5843E ;
}

input:checked + .toggle:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.toggle.round {
  border-radius: 34px;
}
.toggle.round:before {
  border-radius: 50%;
}
button.bl_table_btn_contract {
  border: 0px !important;
  background: transparent;
  cursor: pointer;
}
button.bl_table_btn_contract i.material-icons.contract-icon {
  color: #F5843E;
  font-size: 36px;
}
.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters {
  float: left;
  padding-left: 0px !important;
}
/* My css */
.bl_header-b22 {
  width: 100%;
  float: left;
}
.bl-heading-box {
  width: 70%;
  float: left;
  text-align: left;
}
.bl-new-user {
  width: 30%;
  float: right;
  text-align: right;
}
.bl-new-user button.bl_btn.bl_btn2 {
  background: #F5843E;
  border: 0px;
  color: rgba(255,255,255,1);
  padding: 10px 20px !important;
  border-radius: 8px !important;
  z-index: 1 !important;
  font-size: 16px;
  transition: all 0.5s;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  animation-duration: 1s;
  animation-fill-mode: both;
  cursor: pointer;
}
/* .bl-new-user button.bl_btn.bl_btn2:hover {
  background: rgba(107, 107, 107, 0.4);
  border: solid 1px #f6853f;
} */
button.bl_table_btn_contract i {
  color: #F5843E;
  font-size: 36px;
}
table tr.MuiTableRow-root:nth-child(even) {
  background: #F5F5F5;
}
.bl_container.list-b-bo {
  padding: 60px 0px !important;
  width: 100%;
}
.sreach-fff {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
}
.sreach-fff.search-partner{
  flex-direction: row !important;
  align-items: center;
}
.bl_container.list-b-bo .makeStyles-root-1 {
  margin-top: 40px !important;
}
.new-partner-toggle-box {
  width: 50%;
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
}
.toggle-lable-box {
  margin-left: 20px;
  font-weight: bold;
  color: #000;
  font-size: 18px;
}
.sreach-fff.search-partner .usr-m-search {
  width: 50%;
}
.search-partner .usr-m-search .usr-search {
  width: 60%;
}
.sreach-fff .label-searc {
  text-align: left;
  padding-left: 0px;
}
.sreach-fff .usr-search {
  float: left !important;
}
.sreach-fff .search-btn {
  float: left !important;
  margin-top: 5px !important;
}
.bl-main-box-wrap.search-add-new .makeStyles-root-3 {
  margin-top: 30px !important;
}
.bl-main-box-wrap.search-add-new .makeStyles-root-29
{
  margin-top: 30px !important;
}
.box-arrow-filter {
  display: flex;
}
.sreach-fff .label-searc {
  display: none;
}
.error-no-record {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 20vh;
  align-items: center;
}