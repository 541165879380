.busines-name-main {
    width: 100%;
    float: left;
}
.busines-name-b {
    max-width: 1170px;
    width: 100%;
    margin: 0px auto;
}
.busines-name-b h2 {
    color: #232319 !important;
    text-decoration: none;
    font-size: 26px;
}