.cross-icon-1 {
    position: absolute;
    right: 8px;
    top: 8px;
}
.cross-icon-1 span.material-icons {
    color: orange;
    border: solid 2px orange;
    border-radius: 3px;
}
.btin button {
    color: orange !important;
    font-size: 18px;
    background: transparent;
    border: 0px;
    padding: 8px 30px !important;
    border-radius: 30px !important;
    cursor: pointer;
    text-decoration: none;
    border: solid 2px orange !important;
}
.btin {
    width: 100%;
    float: left;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
}
.input-file-new {
    width: 100%;
    float: left;
    margin-top: 36px;
    margin-bottom: 20px;
}
.ReactCrop__drag-handle::after{
    width: 15px !important;
    height: 15px !important;
    background-color: rgba(0, 0, 0, 0.4) !important;
}
.input-file-new input {
    width: 100%;
}
.pe-inp-btn-upload {
    display: none;
  }
.input-file-new-1 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    flex-direction: column;
}
.btin span.material-icons {
    font-size: 16px;
    float: left;
    margin-top: 6px;
    margin-right: 8px;
}
.file-croping-box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.btin-2 {
    width: 100%;
    float: left;
    margin-top: 14px;
}
.btin-2 button {
    background: transparent;
    border: none;
    color: #1971FF;
    text-transform: uppercase;
    cursor: pointer;
}
.btin-2 button span.material-icons {
    float: left;
    font-size: 18px;
    margin-top: 4px;
    margin-right: 6px;
}
button.retailer-link-b {
    bottom: 0px;
    color: #fff !important;
    font-size: 18px;
    background: var(--primaryColor);
    border: solid 2px var(--primaryColor) !important;
    padding: 10px 30px !important;
    border-radius: 30px !important;
    text-decoration: none;
    cursor: pointer;
}

.reeoe-handle.box-upload {
    min-height: unset !important;
}
.file-upload-model {
    width: 100%;
    float: left;
    max-height: 85vh;
    overflow: auto;
}
.file-upload-model .cross-icon-1 {
    z-index: 999999;
}
.file-upload-model.file-upload-model .input-file-new {
    margin-top: 20px;
    margin-bottom: 10px;
}
.file-upload-model.file-upload-model span.material-icons {
    cursor: pointer;
}

h2.heading-home {
    color: #000;
    width: 100%;
    float: left;
    text-align: center;
    font-family: "inter-semibold";
    font-size: 32px;
    padding-top: 18px;
    line-height: 48px;
}

.reeoe-handle {
    width: 100%;
    float: left;
    min-height: 191px;
  }

.pe-file-container-upload {
    margin: 0px auto;
    width: 120px;
    left: 0px !important;
    }

    label.pe-upload-btn:hover i {
        margin-top: -34px;
      }
      label.pe-upload-btn i.material-icons {
        color: #fff;
        font-size: 35px;
      }
      label.pe-upload-btn:hover .camera-upload-hover {
        display: block;
        width: 120px;
        height: 60px;
        border-bottom-left-radius: 100px;
        background: #9d9d9d;
        border-bottom-right-radius: 100px;
        /* display: flex;
        justify-content: center;
        align-items: center; */
        position: absolute;
        /* margin-top: -63px; */
        top: 63px;
        color: #fff;
        font-size: 14px;
        font-family: 'AvenirNext-DemiBold';
      }

      .upload-banner-select label.pe-upload-btn-upload .camera-upload-hover-upload{
        display: block !important;
        bottom: 0px;
        color: #fff;
        font-size: 18px;
        background: transparent !important;
        border: 0px;
        color: var(--primaryColor);
        padding: 10px 20px !important;
        border-radius: 8px !important;
        margin-left: 240px;
    }

    label.pe-upload-btn {
        width: 125px;
        height: 125px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.8em 2em;
        border-radius: 100px;
        cursor: pointer;
        background-color: #c8c8c8;
      }

    label.pe-upload-btn-upload {
        width: 120px;
       height: 120px;
       display: flex;
       justify-content: center;
       align-items: center;
       padding: 0.8em 2em;
       border-radius: 100px;
       cursor: pointer;
       background-color: #c8c8c8;
    }


.upload-instruction-text {
    color: #000!important;
    font-family: "inter-semibold";
    font-size: 15px;
    margin: 0px;
    line-height: 24px;
  }

    label.pe-upload-btn-upload.pe-upload-btn.ss {
        width: 125px;
        height: 125px;
        margin-bottom: .5rem;
    }

    .btin.bottom {
        margin-bottom: 20px;
    }
    .pe-file-container-new-one {
        width: 125px;
        margin: 0px auto;
    }
    .pe-file-container-new-one label.pe-upload-btn:hover .camera-upload-hover{
        top: 204px;
    }
@media screen and (max-width: 480px) {
    .file-upload-model.file-upload-model .input-file-new {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .pop-mobile-file {
        width: 90% !important;
        min-width: 90% !important;
    }
    .file-upload-model.file-upload-model h2.heading-home {
        font-size: 22px;
    }
    .btin {
        margin-bottom: 60px !important;
    }
}