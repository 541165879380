.bp_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  box-sizing: border-box;
  flex-direction: column;
  /* background-color: gold; */
}
.bp_container .bp_header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: cadetblue; */
  width: 100%;
}
.pe-file-container {
  position: relative;
  left: 7%;
  /* margin-top: 40px; */
  width: 25%;
}

button.ubl-bp {
  background: #f5843e;
  border: none;
  padding: 8px 20px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
}

i.fa.fa-refresh.fa-spin.bc{
  color: orange;
  font-size: 20px;

}
/* margin-right: -41px;
margin-top: 11px;
margin-left: 14px; */

.bootle {
    cursor: pointer;
}

.pe-inp-btn {
  display: none;
}
.pe-upload-icon {
  font-size: 18px;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.pe-img-container1 {
  position: relative;
  margin-top: 1px;
  left: 7%;
  /* display: flex; */
  width: 25%;
  align-items: center;
  justify-content: center;
}
.pe-img-icon1 {
  height: 125px;
  width: 125px;
  border-radius: 50%;
  overflow: hidden;
  background: #c8c8c8;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
}
button.pe-btn1 {
  float: right;
  border: solid 1px red;
  background: transparent;
  padding: 2px;
  position: absolute;
  left: 100px;
  color: #000;
  display: none;
}
button.pe-btn1 i.material-icons.pe-btn-clear {
  font-size: 14px;
  line-height: unset;
}
.pe-img-container1.logo-b12:hover button.pe-btn1 {
  display: block;
}
.pe-text-block1 {
  width: 125px;
  height: 62.5px;
  border-bottom-left-radius: 100px;
  background: rgba(0, 0, 0, 0.5);
  border-bottom-right-radius: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  padding-top: 10px;
  margin-top: -62.5px;
  position: relative;
  z-index: 999;
  margin-left: 0px;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pe-img-container1.logo-b12:hover .pe-text-block1 {
  display: block;
}
.pe-p1-1 {
  font-size: 14px;
  font-weight: 500;
  padding: 0px 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120px;
}
.pe-p1-2 {
  font-size: 10px;
  font-weight: 500;
  color: #777777;
  margin-top: 10px;
}

.bp_container .bp_header .bp_btn0 {
  all: unset;
  position: absolute;
  right: 0;
  /* height: 100%;
    width: 100%; */
  /* margin-top: 40px; */
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 20px;
  border: 1px solid #1a2b57;
  color: #1a2b57;
  cursor: pointer;
  box-sizing: border-box;
}

.bp_container .bp_header .bp_btn0 {
  background: #f5843e;
  border: 0px;
  color: rgba(255, 255, 255, 1);
  padding: 10px 20px !important;
  border-radius: 8px !important;
  z-index: 1 !important;
  font-size: 16px;
  transition: all 0.5s;
  position: absolute;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  cursor: pointer;
}
.bp_container .bp_header .bp_btn0::before {
  border-radius: 8px !important;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bp_container .bp_header .bp_btn0::after {
  border-radius: 8px !important;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid rgba(245, 96, 12, 0.5);
  -webkit-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* .bp_container .bp_header .bp_btn0:hover {
  background: rgba(107, 107, 107, 0.4);
  border: solid 1px #f6853f;
} */
.bp_container .bp_content_container {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bp_container .bp_content_container .bp_list_container {
  display: flex;
  margin-top: 20px;
}
.bp_container .bp_content_container .bp_list_container .bp_p1 {
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
}
.bp_container .bp_content_container .bp_list_container .bp_list {
  border: 1px solid black;
  width: 300px;
  padding: 10px;
  margin-left: 20px;
  height: 140px;
  overflow: auto;
}
.bp_container .bp_content_container .bp_list_container .bp_list .bp_p2 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  cursor: pointer;
}
/* .bp_container .bp_content_container .bp_list_container .bp_list .bp_p2:hover {
  background-color: #909090;
} */

.bp_container .bp_content_container .bp_btn_container {
  display: flex;
  margin-top: 60px;
}

.bp_container .bp_content_container .bp_btn_container .bp_btn0,
.bp_container .bp_content_container .bp_btn_container .bp_btn1,
.bp_container .bp_content_container .bp_btn_container .bp_btn2,
.bp_container .bp_content_container .bp_btn_container .bp_btn3 {
  background: #f5843e;
  border: solid 2px #f5843e;
  color: #ffffff !important;
  padding: 8px 20px !important;
  border-radius: 8px !important;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
.bp_container .bp_content_container .bp_btn_container .bp_btn0 {
  margin: 0 20px;
}
/* .bp_container .bp_content_container .bp_btn_container .bp_btn0:hover,
.bp_container .bp_content_container .bp_btn_container .bp_btn1:hover,
.bp_container .bp_content_container .bp_btn_container .bp_btn2:hover,
.bp_container .bp_content_container .bp_btn_container .bp_btn3:hover {
  background: rgba(107, 107, 107, 0.4);
  
} */

.bp_container .bp_content_container .bp_btn_container .bp_btn2 {
  margin: 0 20px;
}
.bp_btn_container.change-box {
  width: 100%;
}
.bp_btn_ch_left {
  width: 50%;
  float: left;
}
.bp_btn_ch_left.right {
  text-align: right;
}
.bp_btn_container.change-box.margin {
  margin-top: 30px;
}

/* upload button css */

label.pe-upload-btn {
  width: 125px;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8em 2em;
  color: 'purple';
  border-radius: 100px;
  cursor: pointer;
  background-color: #c8c8c8;
}
label.pe-upload-btn i.material-icons {
  color: #fff;
  font-size: 35px;
}
/* .camera-upload-hover {
  width: 125px;
  height: 63px;
  border-bottom-left-radius: 100px;
  background: #9d9d9d;
  border-bottom-right-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: -63px;
  bottom: 0px;
  color: #fff;
  font-size: 14px;
} */
label.pe-upload-btn:hover i {
  margin-top: -34px;
}
label.pe-upload-btn:hover .camera-upload-hover {
  display: block;
  width: 125px;
  height: 63px;
  border-bottom-left-radius: 100px;
  background: #9d9d9d;
  border-bottom-right-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* margin-top: -63px; */
  top: 63px;
  color: #fff;
  font-size: 14px;
}
.camera-upload-hover {
  display: none;
}
.bp-b-b-box .li_container {
  /* height: 80px; */
  margin-bottom: 20px;
}
.bp-b-b-box .lddi_input_container {
  height: 80px;
}
.b-b-p .li_container .li_input_container {
  width: 300px;
}

/* salman */
.react-tel-input .form-control {
  border: 1px solid #cacaca !important;
}

.input-b.margin
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  /* outline: none; */
  height: 48px;
  color: #666666;
  border-radius: 4px;
  padding: 10px;
  width: 300px;
}

.salman-label {
  position: absolute;
}

.moazam-label {
  position: relative;
  right: -16px;
  font-weight: bold;
}

.moazam-label-state {
  position: relative;
  right: -37px;
  font-weight: bold;
}

.moazam-label-city {
  position: relative;
  right: -45px;
  font-weight: bold;
}

.moazam-label-phone {
  position: relative;
  right: 32px;
  font-weight: bold;
}

label.input-lab.state {
  font-size: 16px !important;
  color: #666666 !important;
  font-weight: bold !important;
  font-family: Arial, Helvetica, sans-serif !important;
}

.react-tel-input .form-control {
  width: 300px !important;
  margin-left: 110px !important;
}

.flag-dropdown {
  margin-left: 110px !important;
}

.input-b.margin input.form-control {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: Arial !important;
}
.bp-b-b-box .box-cate-input .li_container {
  height: unset;
  margin-bottom: 10px;
}
.input_name_f_new p.li_error_text {
  width: 300px;
}
.inpt_pass_new p.li_error_text {
  width: 300px;
}
.b-b-p-2 p.li_error_text {
  width: 300px;
}
.inpt_pass_new .generate-btn {
  margin-top: 0px;
  margin-bottom: 20px;
}