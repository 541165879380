
/*progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
  width:100%;
  text-align: center;
  padding-top: 50px;
  float: left;
}
#progressbar li {
  list-style-type: none;
    color: rgb(51, 51, 51);
  text-transform: uppercase;
  font-size: 9px;
  width: 33%;
  float: left;
  position: relative;
}
#progressbar li:before {
  /* content: counter(step);
  counter-increment: step; */
  width: 43px;
  line-height: 40px;
  display: block;
  font-size: 16px;
  color: #333;
  background: #eee;
  border-radius: 50px;
  margin: 0 auto 5px auto;
  border: solid 2px #1a2b57;
  margin-top: -12px;
}
ul#progressbar li a {
  width: 43px;
  line-height: 40px;
  display: block;
  font-size: 16px;
  color: #333;
  background: #eee;
  border-radius: 50px;
  margin: 0 auto 5px auto;
  border: solid 2px #1a2b57;
  margin-top: -12px;
  text-decoration: none;
}
/*progressbar connectors*/
#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: #eee;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1; /*put it behind the numbers*/

}
#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none; 
}
/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,  #progressbar li.active:after{
  background: #1a2b57;
  color: white;
  font-weight: bold;
}
#progressbar li.active a{
  background: #1a2b57;
  color: white;
  font-weight: bold;
}
.five-p-bar li {
  width: 20% !important;
}
ul.four-p-bar li {
  width: 25% !important;
}
.conatiner-progresbar {
  width: 45%;
  margin: 0px auto;
}
.conatianer-p-4 {
  width: 100%;
  float: left;
}
ul#progressbar {
  width: 70%;
  float: none;
  margin: 0px auto;
}
.three a {
  pointer-events: none;
}