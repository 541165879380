.la_conatiner {
    /* padding: 60px 100px; */
    padding: 60px;
    box-sizing: border-box;
    /* background-color: gold; */
    text-align: center;
    width: 100%;
    float: left;
}
.la-heading {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 60px;
}
.la-col-wrap .la-column-box {
    width: 100%;
    float: left;
    text-align: left;
    
}
.la-col-wrap .la-column-box.header{
    padding: 10px;
    background: #1a2b57;
}
.scroll-la {
    max-height: 60vh;
    overflow: auto;
    width: 100%;
    float: left;
}
.la-col-1 h5 {
    color: #fff !important;
    font-size: 15px;
}
.la-col-2 h5 {
    color: #fff !important;
    font-size: 15px;
}
.la-col-1 {
    width: 18%;
    float: left;
}
.la-col-2 {
    width: 30%;
    float: left;
}
.la-col-wrap {
    width: 100%;
    float: left;
    border: solid 1px #ccc;
    /* max-height: 60vh;
    overflow: auto; */
}
.la-column-box.border {
    padding: 5px 10px;
    border-bottom: solid 1px #ccc;
}
.la-col-1 .in-header-btn-box {
    text-align: left;
}
.la-column-box-in.border {
    display: flex;
    width: 100%;
    /* height: 50px; */
    align-items: center;
    text-align: left;
    padding: 5px 10px;
    border-bottom: solid 1px #ccc;
}
.la-column-box-in.border p{
    word-break: break-all;
    padding-right: 10px;
}
.empty-text{
    text-align: center;
    padding: 30px 0px;
    width: 100%;
    float: left;
    font-size: 20px;
    font-weight: bold;
}
.la-column-box-in:nth-child(even) {
    background: #F5F5F5;
}


.tags {
    display: inline;
    position: relative;
  }
  
  .tags:hover:after {
    background-color: #666666;
    color: #ffffff;
    
    top: 18px;
    content: attr(glose);
    /* left: 20%; */
    padding: 0px 5px;
    position: absolute;
    z-index: 98;
    width: 200px;
    font-size: 12px;
  }
  .tags:hover:before {
    /* border: solid;
    border-color: #333 transparent;
    border-width: 0 6px 6px 6px; */
    bottom: -4px;
    /* content: ""; */
    left: 50%;
    position: absolute;
    z-index: 99;
  }