.select-search {
    width: 300px;
    position: relative;
    margin-bottom: 20px;
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
}

.select-search *, .select-search *::after, .select-search *::before {
    box-sizing: inherit;
}

.select-search.is-disabled {
    opacity: 0.5;
}

.select-search__value {
    position: relative;
}

ul.select-search__options {
    padding: 0px !important;
}

.select-search__value::after {
    content: "\f078";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
    pointer-events: none;
    color: #000000 !important;
}

.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search__input::-webkit-search-decoration, .select-search__input::-webkit-search-cancel-button, .select-search__input::-webkit-search-results-button, .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search__input:hover {
    border-color: #ccc;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    height: 180px;
    overflow: auto;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__options {
    list-style: none;
}

.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

.select-search__option {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #F1F1F1 !important;
    /* color: #fff; */
}

.select-search__option.is-highlighted, .select-search__option:not(.is-selected):hover {
    background: #F1F1F1 !important;
}

.select-search__option.is-highlighted.is-selected, .select-search__option.is-selected:hover {
    background: #F1F1F1 !important;
    color: #fff;
}

.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

input#react-select-3-input {
    opacity: 1 !important;
}

p.ltai_label {
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    color: #666666;
}

.ov-single-textarea label.ltai_label {
    padding-left: 0px !important;
}

.ov-feild.date-over input#react-select-3-input {
    opacity: unset !important;
}

.avatar {
    width: 16px;
    height: 16px;
}

input[id^='react-select-'] {
    opacity: 1 !important;
  }
.css-g1d714-ValueContainer{
    flex-direction: column !important;
    align-items: flex-start !important;
  }
.css-12jo7m5.imagePrefixInput__multi-value__label {
    width: 100% !important;
}
.css-1rhbuit-multiValue{
    max-width: 90%;
}