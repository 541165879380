.message.upload-banner-wrap {
    width: 100%;
    float: left;
}
.message .upload-banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
    box-sizing: border-box;
    flex-direction: column;
}
.message .upload-banner-select-label {
    font-size: 32px;
    font-weight: bold;
    color: #000000 !important;
  }
  .mes-ret {
    width: 50%;
    float: left;
}
.message .upload-form-input{
    width: 400px;
   
}
.radio-margin{
    padding-top: 4px;
}
.message .upload-form-input-b .ltai_input_container {
    width: 400px;
}
.message .upload-form-input-b .ltai_input_container textarea.ltai_input {
    width: 100% !important;
    border-radius: 4px;
}
.message .li_container .li_input_container .li_error_text {
 
    float: left;
}
.msge-loader {
    bottom: 0px;
    color: #fff !important;
    font-size: 18px;
    /* background: #f6853f; */
    border: 0px;
    padding: 6px 20px !important;
    border-radius: 8px !important;
    width: 94px;
    float: right;
}
.mesg-history-box {
    max-width: 100%;
    margin: 0px auto;
    width: 1170px;
}
.mesge-history-in-b {
    width: 100%;
    float: left;
    border: solid 1px #ccc;
    margin-top: 40px;
}
.msge-head {
    display: flex;
    justify-content: space-around;
    background: #1a2b57;
    text-align: center;
}
.msge-head .head-f {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 0px;
}
.mesg-row-1 {
    width: 20%;
}
.mesg-row-3{
    width: 40%;
}
.msge-row {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    /* padding: 10px 0px; */
    border-bottom: solid 1px #ccc;
}
.msge-row .body-t {
    font-size: 18px;
    color: #575757;
    padding: 10px 5px;
    min-height: 50px;
}
.mesg-row-3.body-t.border {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .msge-row .border {
    border-right: solid 1px #ccc;
} */
.msge-row:nth-child(even) {
    background: #F5F5F5;
}
.msge-scroll {
    max-height: 70vh;
    overflow: auto;
}
.ltai_input_container textarea.ltai_input {
    font-family: Arial, Helvetica, sans-serif;
}
.upload-banner-wrap.message .ltai_container .ltai_label {
    color: #666666 !important;
    text-align: right !important;
    font-weight: bold !important;
    font-size: 18px !important;
}
.upload-banner-wrap.message .li_container .li_label {
    font-size: 18px !important;
}
.upload-form-input-b.select-business .li_input_container {
    width: 400px;
}


@media (min-width: 768px) and (max-width: 991px) {
    .in-header-btn-box button.inner-header-btn{
        padding: 8px 12px !important;
        font-size: 12px;
    }

    .mesge-history-in-b {
        padding: 40px 50px 0px 50px;
        margin: 38px;
        margin: 0px 50px 0px 75px;
        border: none;
    }

    .upload-form-button-box{
        padding-right: 50px;
    }
}