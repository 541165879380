.upload-banner-wrap {
    width: 100%;
    float: left;
}
.upload-banner-new-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 60px; */
    box-sizing: border-box;
    flex-direction: column;
}
.upload-banner-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.upload-banner-select-label-upload {
    width: 100%;
    float: left;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 26px;
}
.upload-banner-select label.pe-upload-btn-upload .camera-upload-hover-upload{
  display: block !important;
  bottom: 0px;
  color: #fff;
  font-size: 18px;
  background: #F5843E;
  border: solid 2px #F5843E !important;
  color: rgba(255,255,255,1);
  padding: 8px 20px !important;
  border-radius: 8px !important;
  margin-left: 240px;
}
label.pe-upload-btn-upload {
    width: 82px;
   height: 82px;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 0.8em 2em;
   color: "purple";
   border-radius: 100px;
   cursor: pointer;
   background-color: #c8c8c8;
}



.pe-file-container-upload {
    position: relative;
    left: 7%;
    /* margin-top: 40px; */
  }
  .pe-inp-btn-upload {
    display: none;
  }
  .pe-upload-icon-upload {
    font-size: 18px;
    position: relative;
    top: 5px;
    margin-right: 10px;
  }
  .pe-img-container1-upload {
    position: relative;
    margin-top: 1px;
    left: 7%;
    /* display: flex; */
    align-items: center;
    justify-content: center;
  }
  .pe-img-icon1-upload {
    height: 82px;
    width: 82px;
    border-radius: 50%;
    overflow: hidden;
    background: #c8c8c8;
    align-items: center;
    justify-content: center;
    text-align: center;
    float: left;
  }
  button.pe-btn1-upload {
    float: right;
    border: solid 1px red;
    background: transparent;
    padding: 2px;
    position: absolute;
    left: 72px;
    color: #000;
  }
  button.pe-btn1-upload i.material-icons.pe-btn-clear {
    font-size: 14px;
    line-height: unset;
  }
  .pe-img-container1-upload.logo-b12:hover button.pe-btn1 {
    display: block;
  }
  .pe-text-block1-upload {
    width: 160px;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 12px;
    padding-top: 10px;
    float: left;
    height: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.pe-text-block1-upload {
    display: block;
  }
  .pe-p1-1-upload {
    font-size: 14px;
    font-weight: 500;
    padding: 0px 14px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .banner-up {
    width: 82px;
    height: 41px;
    border-bottom-left-radius: 100px;
    background: rgba(0, 0, 0, 0.5);
    border-bottom-right-radius: 100px;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 12px;
    padding-top: inherit;
    position: absolute;
    bottom: 0px;
}
.upload-banner-select-label {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 26px;
}
.upload-banner-input-box {
  width: 100%;
  float: left;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.upload-form-input-b {
  display: flex;
  margin-top: 30px;
}
.upload-form-label {
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
  color: #666666;
}
.select-search__value {
  width: 300px;
}
.upload-form-input {
  width: 300px;
  margin-left: 20px;
}
.upload-form-input input.dapi_input.box-input-date {
  width: 100%;
}
.select-search.select-search--search {
  margin-bottom: 0px !important;
}
.select-search.select-search--search {
  margin-bottom: 0px;
}
input.select-search__input {
  border: 1px solid #cccccc;
  height: 48px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  padding: 10px;
  box-shadow: 0px !important;
}
.upload-form-input .drop_down_input_container {
  border: solid 1px #ccc;
  height: 48px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  padding: 10px;
}
.upload-form-radio {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  width: 300px;
  margin-top: 30px;
}
.upload-form-r-box label.radio-container {
  font-size: 18px;
  font-weight: bold;
  box-sizing: border-box;
  color: #666666;
}
.upload-form-r-box label.radio-container {
  font-size: 18px;
  font-weight: bold;
  box-sizing: border-box;
  color: #666666;
}
button.upload-button {
  bottom: 0px;
  color: #fff !important;
  font-size: 18px;
  background: #F5843E;
  border: 0px;
  padding: 10px 20px !important;
  border-radius: 8px !important;
  cursor: pointer;
}
.upload-form-button {
  display: flex;
  padding: 50px 0px;
}
.upload-form-r-box select.drop_down_input {
  padding: 0px 12px 0px 0px !important;
}
input#si_input {
  width: 100%;
  height: 48px;
  padding: 10px !important;
  border-radius: 4px;
}
p.clddi_error_text {
  width: 100%;
  float: left;
}
.upload-form-input .clddi_input_container {
  display: unset;
}
.existing-input.margin input.dapi_input.box-input-date {
  width: 100%;
}
.existing-input.margin  .box-date-picker > div {
  width: 100%;
}
.existing-input.margin {
  width: 100%;
  float: left;
}
p.error-upload {
  width: 100%;
  float: left;
  color: red;
}
.upload-form-button-box {
  width: 100%;
  float: left;
  padding-top: 40px;
  padding-bottom: 20px;
}
.back-btn {
  text-align: left;
  width: 50%;
  float: left;
  margin-top: 11px;
}
.next-btn {
  width: 50%;
  float: left;
  text-align: right;
  justify-content: right;
}
a.back-link {
  color: #F5843E !important;
  font-size: 18px;
  background: transparent;
  border: 0px;
  padding: 8px 20px !important;
  border-radius: 8px !important;
  cursor: pointer;
  text-decoration: none;
  border: solid 2px #F5843E !important;
}
.upload-coupon-header {
    width: 100%;
    float: left;
    /* padding-bottom: 60px; */
}


/* Retalier image */

.retailer-upload-box {
    width: 100%;
    float: left;
}
.retailer-upload-container {
    max-width: 1170px;
    width: 100%;
    margin: 0px auto;
}
.retailer-upload-inner {
    width: 100%;
    float: left;
}
.retailer-up-box {
    width: 100%;
    float: left;
    padding-bottom:60px
}
.reatiler-search-box {
    width: 70%;
    float: left;
}
.retailer-links {
    float: right;
    /* padding-top: 40px; */
}
.retailer-label {
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    float: left;
    padding-bottom: 14px;
    color: #666666 !important;
}
.retailer-input-search {
    width: 100%;
    float: left;
}
a.retailer-link-b {
    display: block !important;
    bottom: 0px;
    color: #fff !important;
    font-size: 18px;
    background: #F5843E;
    border: solid 2px #F5843E !important;
    padding: 10px 20px !important;
    border-radius: 8px !important;
    text-decoration: none;
    cursor: pointer;
}
/* a.retailer-link-b:hover {
  background: rgba(107, 107, 107, 0.4);
} */
.retailer-upload-header {
    width: 100%;
    float: left;
    padding-bottom: 60px;
}
p.emty-screen-msge {
  font-size: 24px;
  color: #707070;
  font-weight: bold;
  text-align: center;
}
.box-card-retailer {
  width: 100%;
  float: left;
  height: 80vh;
  overflow: auto;
}
.qustion-input input.dapi_input.box-input-date {
  padding-left: 16px !important;
}
.qustion-input select.drop_down_input {
  padding-left: 16px;
}
.image-text h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.image-t-des {
  font-weight: 400;
}
.image-text-tt h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.image-t-des {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.upload-banner-box.admin-new {
  width: 560px;
}
.upload-banner-select-label.new-label {
  padding-bottom: 0px;
}
.upload-banner-box.admin-new p.error-upload {
  text-align: center;
}
.croping-im-error {
  width: 100%;
  float: left;
  margin-top: 6px;
}
.croping-video-offer{
  width: 400px;
  height: 300px;
}
button.upload-button-disable {
  bottom: 0px;
  color: #fff !important;
  font-size: 18px;
  background: rgba(245, 132, 61, 0.6);
  border: 0px;
  padding: 10px 20px !important;
  border-radius: 8px !important;
  pointer-events: none;
}
.upload-banner-box2 img {
  max-width: 100%;
}
.upload-banner-box.admin-new.psw label.pe-upload-btn-upload.pe-upload-btn {
width: 125px;
height: 125px;
margin-bottom: 15px;
}
.croping-video-offer video {
  position: unset !important;
  width: 100%;
}
.croping_img {
  border: solid 1px #ccc;
  width: 339px;
  height: 228px;
}


@media (min-width: 768px) and (max-width: 991px) {
  .retailer-links{
    float: right;
    padding-right: 60px;
  }
}