.asm-main-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  min-height: 100vh;
}
i.material-icons.asm-icon {
  border: solid 1px #000;
  border-radius: 50px;
}
.asm-container {
  background-color: white;
  padding: 60px;
  box-sizing: border-box;
  /* flex-direction: column; */
}
.asm-container .asm-main-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.asm-container .asm-main-heading .asm-icon {
    font-weight: 600;
    cursor: pointer;
}
.asm-content-boc {
  margin-top: 40px;
}
.asm-content-boc {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.asm-text-message {
  margin-top: 20px;
}
.asm-display-box {
  margin-top: 20px;
}
.asm-display-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.asm-display-input input.li_input {
  width: 155px !important;
  margin-right: 12px;
}
label.ltai_label {
  font-size: 17px;
  font-weight: bold;
  box-sizing: border-box;
  color: #666666;
}
.asm-drowpdown{
    margin-top: 20px;
}
button.asm-btn-button {
  background: #F5843E;
  border: 0px;
  color: rgba(255, 255, 255, 1);
  padding: 10px 20px !important;
  border-radius: 8px !important;
  z-index: 1 !important;
  font-size: 16px;
  transition: all 0.5s;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  animation-duration: 1s;
  animation-fill-mode: both;
  cursor: pointer;
}
/* button.asm-btn-button:hover {
  background: rgba(107, 107, 107, 0.4);
  border: solid 1px #f6853f;
} */
.asm-box-btn {
  margin-top: 40px;
}
