.si_input {
  box-sizing: border-box;
  border: 1px solid #cccccc;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  outline: none;
  height: 48px;
  color: #666666;
  vertical-align: middle;
  font-family: "Poppins", sans-serif;
  padding: 8px 10px;
  width: 100%;
}

.si_input:active {
  border: solid 1px #94a4b4;
}
.si_error_text {
  margin-top: 5px;
  font-size: 12px;
  color: red;
  text-align: left;
}
