.app-model-box {
  width: 100%;
  float: left;
  padding: 16px;
  background: #fff;
  border-radius: 8px;
  margin-top: 30px;
}
.app-model-box {
  width: 100%;
  float: left;
}
button.cros-icon-model {
  position: absolute;
  right: 5px;
  top: 5px;
  background: transparent;
  border: 0px;
}
button.cros-icon-model span.material-icons {
  border: solid 1px #000;
  border-radius: 50px;
}
.app-model-inner-box {
  width: 100%;
  float: left;
}
.app-model-head {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

.export-rca {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .export-rca span.MuiButton-label {
    font-size: 10px !important;
    color: white !important;
    font-weight: 700;
  }
  
  .export-rca button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
    background: #f5843e;
    border-radius: 30px;
  }



.col-app-img {
    width: 70px;
    /* height: 70px; */
    float: left;
}
.col-app-img img {
  width: 100%;
}
.approvl-model-box {
  width: 100%;
  float: left;
}
.inv-model-box {
  width: 100%;
  float: left;
  background: #f4f5fa;
  padding: 10px;
  border-radius: 8px;
  max-height: 42vh;
  overflow: auto;
}
.inv-model-inner {
  width: 100%;
  float: left;
}
.inv-card-feild-box {
  width: 100%;
  float: left;
  border-bottom: solid 2px rgba(1, 79, 152, 0.2);
  padding: 10px 0px;
}
.inv-card-left {
  width: 46%;
  float: left;
  font-size: 14px;
}
.inv-card-right {
  text-align: right;
  font-size: 14px;
  width: 54%;
  float: right;
  font-weight: bold;
}
.inv-card-feild-text-area {
  width: 100%;
  float: left;
}
.inv-card-feild-text-area .inv-card-txt {
  width: 100%;
  float: left;
}
.inv-card-feild-text-area .inv-card-txt .ltai_container {
  flex-direction: column;
}
.inv-card-feild-text-area .inv-card-txt label.ltai_label {
  padding-left: 0px;
  font-weight: normal;
  font-size: 14px;
  color: #000;
}
.inv-card-feild-text-area .inv-card-txt .ltai_container .ltai_input_container {
  margin-left: 0px;
}
.inv-card-feild-text-area .inv-card-txt .ltai_container textarea.ltai_input {
  width: 100%;
  border-radius: 4px;
  height: 60px;
}
.inv-card-feild-text-area.border {
  border-bottom: solid 2px rgba(1, 79, 152, 0.2);
  padding-bottom: 10px;
}
.inv-btn-wrap {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: 20px;
  justify-content: space-between;
}
.decl-box {
  /* width: 50%; */
  float: left;
}
.app-btn-box {
  /* width: 50%; */
  float: left;
  text-align: center;
}
.app-preview-box-bbbtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-top: 16px;
}
button.decline-box {
  background: #fff;
  border: solid 2px #f5843e;
  color: #f5843e;
  padding: 8px 20px !important;
  border-radius: 8px !important;
  z-index: 1 !important;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
button.app-box-btn {
  background: #f5843e;
  border: solid 2px #f5843e;
  color: rgba(255, 255, 255, 1);
  padding: 8px 20px !important;
  border-radius: 8px !important;
  z-index: 1 !important;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
.app-head-col2 {
  padding-left: 30px;
  width: 100%;
}
.app-head-col2 span {
  background: #f5843e;
  border: solid 2px #f5843e;
  color: rgba(255, 255, 255, 1);
  padding: 8px 20px !important;
  border-radius: 30px !important;
  z-index: 1 !important;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  float: left;
  text-align: center;
}
button.mat-crcle {
  background: transparent;
  border: none;
}
h4.feedback-heading {
  text-align: center;
  font-size: 24px;
  margin-bottom: 30px;
}
.decl-model-head {
  width: 100%;
  float: left;
  text-align: center;
}
.dec-app-btn-box {
  width: 100%;
  float: left;
  text-align: center;
}
.approvl-model-box.decline textarea.ltai_input {
  height: 140px !important;
}
.deline-model-box label.checkbox-label {
  font-size: 12px !important;
  min-height: 26px;
}
.deline-model-box label.check-wrap.checkbox {
  margin-top: 8px;
}
.error-check-bx {
  width: 100%;
  float: left;
  text-align: left;
}
.err-stles {
  color: red;
  font-size: 12px;
  width: 100%;
  float: left;
  text-align: left;
}
.pdf-box.new-preview-style {
  width: 420px;
  text-align: center;
}
.pdf-box.new-preview-style .card-note-new {
  padding-bottom: 10px;
}
.pdf-box.new-preview-style .text-des {
  margin-bottom: 10px;
}

.legal-term-box {
  background: #ebf1f7;
  width: 100%;
  float: left;
  border-radius: 8px;
  margin-top: 20px;
}

.new-exp-date-two {
  font-size: 15px;
  line-height: 32px;
  font-family: 'inter-medium';
  padding: 4px 20px;
  color: #43003e;
  background: #e1e8f2;
}

.l-term-heading-box {
  background: #d8e5f1;
  width: 100%;
  float: left;
  border-radius: 8px;
  padding: 8px 20px;
  font-size: 16px;
  font-family: 'inter-bold';
  color: #43003e;
  line-height: 44px;
}

.l-term-desc {
  padding: 8px 20px 16px 20px;
  font-family: 'inter-regular';
  font-size: 15px;
  line-height: 32px;
  width: 100%;
  float: left;
  max-height: 240px;
  overflow: auto;
  color: #43003e;
}
/* New Design Pixel Perfect */

.nav-main-status {
  background: #F5843E;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  padding: 8px 10px !important;
  border: solid 2px #f5843e;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
}
.nav-main-status.icon_under {
  display: flex;
}
.nav-main-status span.material-icons {
  float: right;
  width: 24px;
  margin-left: 10px;
}
.box-colapse-status {
  width: 175px;
  float: left;
}
.box-colapse-status button {
  background: transparent;
  color: #000;
  padding: 8px 10px !important;
  border: 0px;
  text-transform: capitalize;
  width: 100%;
  float: left;
  text-align: left;
  font-weight: normal !important;
}
.approval-btn {
  width: 100%;
  float: left;
  text-align: left;
}
.collapse.navbar-collapse.box-colapse-status {
  display: block !important;
}
button.app-box-btn-no {
  background: #fff;
  border: solid 2px #f5843e;
  color: #f5843e;
  padding: 8px 30px !important;
  border-radius: 8px !important;
  z-index: 1 !important;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
.dec-app-btn-yes button.app-box-btn{
  padding: 8px 30px !important;
}
.box-colapse-status .dropdown-menu {
  float: left;
}
.approvl-model-box.decline-two h4.feedback-heading {
  text-align: left;
  margin-bottom: 10px;
}
.decline-two .deline-box-two {
  font-size: 16px !important;
  width: 100%;
  float: left;
  margin-bottom: 40px;
}
.inv-btn-wrap-ma {
  width: 100%;
  float: left;
}
.new-exp-date-three {
  font-size: 15px;
  line-height: 32px;
  font-family: 'inter-medium';
  color: #43003e;
  background: #e1e8f2;
}
.checklist-for-user {
  background: #f4f5fa;
  width: 100%;
  float: left;
  padding: 20px 10px;
  border-radius: 4px;
}
.check-list.mrg-l-chck {
  width: 100%;
  float: left;
  padding-left: 10px;
}