.input-b.margin .MuiAutocomplete-endAdornment {
  width: 55px;
}
.input-b.margin .MuiAutocomplete-endAdornment button {
  width: 50% !important;
  float: left;
}
.input-b label.input-lab {
  width: 100%;
  float: left;
  margin-bottom: 0px;
  padding: 10px 10px 10px 0px;
  color: var(--labelField);
  font-size: 24px;
  font-family: 'inter-regular';
  line-height: 36px;
  font-weight: unset !important;
}
.input-b .MuiOutlinedInput-notchedOutline {
  border-color: var(--primaryColor) !important;
}
.input-b input#combo-box-demo {
  box-sizing: border-box;
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
  outline: none;
  height: 48px;
  color: #666666;
  border-radius: 4px;
  font-family: 'inter-regular';
  padding: 10px;
}
.input-b .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 0px;
}
input.form-control {
  box-sizing: border-box;
  display: inline-block;
  font-size: 20px !important;
  line-height: 30px;
  outline: none;
  height: 48px !important;
  color: #666666 !important;
  border-radius: 4px !important;
  font-family: 'inter-regular';
  padding: 10px;
  width: 100% !important;
  border: solid 1px var(--primaryColor) !important;
}
.react-tel-input .flag-dropdown {
  height: 48px;
  top: unset !important;
}
p.drop_down_input_error_text {
  margin-top: 5px;
  font-size: 12px;
  color: red;
  text-align: left;
}

.b-b-p
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  /* margin-left: 110px; */
  height: 48px;
  /* margin-top: -20px; */
  width: 300px;
}

.b-b-p label.input-lab {
  font-weight: bold;
  color: #666666;
  text-align: right;
  margin-right: -80px;
  margin-top: 14px;

}
.react-tel-input {
  width: unset !important;
}
.b-b-p.state {
  /* margin-top: 14px; */
}
.b-b-p-2{
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}
.b-b-p {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-bottom: 26px;
}
label.input-lab.country {
  margin-right: 30px;
}
label.input-lab.state {
  margin-right: 30px;
}

input#combo-box-demo{
  font-size: 14px !important;
  font-weight: 400;
  line-height: 18px;
  color: #666666;
}

.b-b-p.phone input.form-control {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 18px;
  color: #666666;
}

p.drop_down_input_error_text.auto-select.phone-ca {
  margin-left: 156px;
}
.b-b-p.city .li_container {
  margin-bottom: 0px !important;
}
.b-b-p.zip_code .li_container {
  margin-bottom: 0px;
}
.box_phone_profile {
  margin-bottom: 20px;
}
.box_phone_profile .b-b-p.phone {
  margin-bottom: 0px;
}


/* p.drop_down_input_error_text.auto-select.phone-ca {
  position: relative;
  top: 25px !important;
  left: -299px;
} */


/* .b-b-p.city input.li_input {
  margin-left: 35px;
} */





