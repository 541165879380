.legend_input_container{
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    /* background-color: thistle; */
}
.legend_input_container .legend_fieldset{
    border: 1px solid black;
    border-radius: 5px;
    width: 100%;
}
.legend_input_container .legend_fieldset .legend_heading{
    /* border: 1px solid black; */
    margin-left: 10px;
    font-size: 14px;
    padding: 0 5px ;
}

.legend_input_container .legend_input{
    all: unset;
    width: 100%;
    box-sizing: border-box;
    /* border:1px solid black; */
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
}

.legend_input_container .legend_edit{
    text-decoration: underline;
    font-size: 12px;
    margin-left: 10px;
    align-self: flex-end;
    cursor: pointer;
}