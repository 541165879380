.upload-manage-container {
    max-width: 1170px;
    width: 100%;
    margin: 0px auto;
}
.upload-manage-wrap2 {
    width: 100%;
    float: left;
}
.upload-manage-box {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.popover 
{
    top:23px !important;
    left:100px !important;
    z-index: 1000 !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)
{
    color: #1a2b57 !important;
}
.existing-banner {
    width: 27%;
    float: left;
}
.existing-label {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 10px;
    font-weight: 600 !important;
    color: #666666 !important;
}
.upload-check-box {
    width: 30%;
    float: left;
}
.upload-two-button-box {
    width: 40%;
    float: left;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
input.hidden {
    width: 18px;
    height: 18px;
    float: left;
}
label.checkbox-label {
    font-size: 18px;
    padding-left: 10px;
}
.upload-butto1 a {
    display: block !important;
    bottom: 0px;
    color: #fff !important;
    font-size: 18px;
    background: #F5843E;
    border: 0px;
    padding: 10px 20px !important;
    border-radius: 8px !important;
}
.upload-butto1.margin {
    margin-left: 10px;
}
.check-wrap {
    width: 100%;
    float: left;
    margin-top: 35px;
}
.existing-input.margin {
    margin-bottom: 20px;
}
.upload-next-btn a {
    bottom: 0px;
    color: #fff !important;
    font-size: 18px;
    background: #F5843E;
    border: solid 2px #F5843E !important;
    padding: 8px 30px !important;
    border-radius: 8px !important;
}

.upload-next-btn button {
    bottom: 0px;
    color: #fff !important;
    font-size: 18px;
    background: #F5843E;
    border: solid 2px #F5843E !important;
    padding: 8px 30px !important;
    border-radius: 8px !important;
}
/* .upload-next-btn a:hover{
    background: rgba(107, 107, 107, 0.4);
} */
.upload-next-btn {
    width: 50%;
    float: left;
    text-align: right;
    margin-bottom:30px;
    margin-top: 11px;
}
.event-container{
    height: 100% !important;
}
.timeline-event {
    position: absolute;
    margin-top: 28px;
}
/********************* Graph Design *************************/
label.ant-radio-button-wrapper {
    pointer-events: none;
}
label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    pointer-events: unset;
}
.ant-row-flex  .ant-col .header2-text {
    pointer-events: none;
}
.upload-form-button-box.margin {
    padding-left: 10px;
    padding-right: 10px;
}
label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
   
    border-color: #F5843E !important;
}
.event-item
{
    /* color: #f6853f !important; */
    border-color: #F5843E !important;
    background-color: #F5843E !important;

}
#RBS-Scheduler-root a
{
    color: #1a2b57 !important;
}
.status-dot
{
    background-color: #1a2b57 !important;
    width: 12px;
    height: 12px;

}

body .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
     border-color: #F5843E !important;
     border-left: solid 1px;
     opacity: unset !important;
}
.reatiler-b {
    width: 23%;
    float: left;
    margin-left: 16px;
}
.reatiler-b .card-box {
    width: 94%;
}
p.select-empty {
    width: 100%;
    float: left;
    margin-top: 30px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}
h3.retailer-name-h {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    padding-left: 20px;
    margin-bottom: 20px;
    height: 28px;
    float: left;
    width: 100%;
}
.empty-retailer {
    height: 48px;
}

.DayPicker-Day.DayPicker-Day--selectedRange.DayPicker-Day--selectedRangeStart.DayPicker-Day--selected {
    background: #F5843E !important;
    border: solid 1px #F5843E;
}
.DayPicker-Day.DayPicker-Day--selectedRange.DayPicker-Day--selected {
    background: RGBA(246,133,63,0.49) !important;
    border: solid 1px #F5843E;
}
.DayPicker-Day.DayPicker-Day--selectedRange.DayPicker-Day--selectedRangeEnd.DayPicker-Day--selected {
    background: #F5843E !important;
    border: solid 1px #F5843E !important;
}
.upload-card-box.margin-check {
    padding-top: 20px;
    height: unset !important;
    overflow: unset !important;
}
.banner-s {
    width: 100%;
    float: left;
}
.existing-banner.second-b {
    margin: 0px auto;
    float: unset;
}
.existing-banner.second-b span.material-icons {
    height: 48px;
    width: 40px;
    background: #eee;
    color: #F5843E !important;
    font-size: 20px;
    border: solid 1px #ccc;
    align-items: center;
    justify-content: center;
    display: flex;
    float: left;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.existing-banner.second-b .date-rang2 {
    width: 86%;
    float: left;
}
.existing-banner.second-b input#si_input {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    }
    label.checkbox-label {
        color: #666666 !important;
        font-weight: 600 !important;
        margin-top: 3px;
    }
    /* .retailer-b img {
        max-width: 140px !important;
    } */
    .existing-banner.box-weeks {
        width: 100% !important;
        margin-top: 40px;
    }
    .weekbo-2 {
        width: 35%;
        float: left;
    }
    .weekbo-2.mar {
        margin-left: 20px;
    }
    .scheduler td {
        height: 90px;

    }
    .resource-table td {
        height: 98px;
    }
    .resource-view{
        width: 320px;

    }
    .resource-view div{
        overflow: visible !important;
        width: auto !important;
    }

    [data-title]:hover:after {
        opacity: 1;
        transition: all 0.1s ease 0.5s;
        visibility: visible;
    }
    [data-title]:after {
        content: attr(data-title);
        background-color: #666666;
        color: #ffffff;
        font-size: 12px;
        position: absolute;
        padding: 0px 5px;
        /* box-shadow: 1px 1px 3px #222222; */
        /* border: 1px solid #111111; */
        z-index: 9;
        visibility: hidden;
        width:200px;
        word-break: break-all;
        /* left: 20px; */
        top: 0;
        margin-left: 20px;
        margin-top: 10px;
    }
    [data-title] {
        position: relative;
    }

    
    [data-title2]:hover:after {
        opacity: 1;
        transition: all 0.1s ease 0.5s;
        visibility: visible;
    }
    [data-title2]:after {
        content: attr(data-title2);
        background-color: #666666;
        color: #ffffff;
        font-size: 12px;
        position: absolute;
        padding: 0px 5px;
        /* box-shadow: 1px 1px 3px #222222; */
        /* border: 1px solid #111111; */
        z-index: 9;
        visibility: hidden;
        width:200px;
        word-break: break-all;
        top: 0;
        margin-left: 20px;
        margin-top: 10px;
        z-index: 1;
    }
    [data-title2] {
        position: relative;
    }
    .reatiler-b.b-head p.hhh {
        display: none;
    }
    input.dapi_input.box-input-date {
        background: #fff !important;
    }


