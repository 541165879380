.approval-body-box {
    width: 100%;
    float: left;
    margin-top: 30px;
}
.filter-ap-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: center;
}
.filter-ap-first {
    width: 22.5%;
    float: left;
    margin: 0px 10px;
}
.filter-ap-first .lddi_input_container {
    flex-direction: column;
}
.filter-ap-first label.lddi_label {
    color: #000;
}
.filter-ap-first .lddi_input_container .lddi_div1 {
    margin-left: 0px;
}
.filter-ap-first .lddi_input_container .lddi_div1 .lddi_container {
    width: 100%;
}
.box-table-approval {
    width: 100%;
    float: left;
}
table.table-approval {
    width: 100%;
    border: 1px solid rgba(224, 224, 224, 1);
    border-spacing: 0px
}
.table-box-app {
    width: 100%;
    float: left;
    margin-top: 30px;
}
thead.table-app-head {
    padding: 30px;
}
.bl_container table thead.table-app-head th {
    padding: 18px 0px;
}
tbody.table-app-body td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 16px 0px;
}
tbody.table-app-body tr:nth-child(even) {
    background: #F5F5F5;
}
h1.parner-empt {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 40vh;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
}
.no-coupons {
    width: 100%;
    float: left;
    margin-top: 40px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}


.coupon-approval-filter-button a {
    display: block !important;
    bottom: 0px;
    color: #fff !important;
    font-size: 18px;
    background: #F5843E;
    border: 0px;
    padding: 10px 20px !important;
    border-radius: 8px !important;
    margin-top: 35px;
}

.filter-ap-first-button{
        width: 10%;
        float: left;
        margin: 0px 10px;
}
.btite-elpse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
    margin: 0px auto;
}
.box-icon-show {
    display: flex;
    justify-content: space-around;
}