.upload-manage-container {
  max-width: 1170px;
  width: 100%;
  margin: 0px auto;
}
.upload-manage-wrap2 {
  width: 100%;
  float: left;
}
.upload-manage-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.existing-banner {
  width: 27%;
  float: left;
}
.existing-label {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
}
.upload-check-box {
  width: 30%;
  float: left;
  margin-top: 18px;
}
.upload-two-button-box {
  width: 40%;
  float: left;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
input.hidden {
  width: 18px;
  height: 18px;
  float: left;
}
label.checkbox-label {
  font-size: 18px;
  padding-left: 10px;
}
.upload-two-button-box .upload-butto1 a {
  display: block !important;
  border: solid 2px #f5843e !important;
  color: #fff !important;
  font-size: 18px;
  background: #f5843e;
  padding: 8px 20px !important;
  border-radius: 8px !important;
}
/* .upload-two-button-box .upload-butto1 a:hover {
    background: rgba(107, 107, 107, 0.4);
} */
.upload-butto1.margin {
  margin-left: 10px;
}
.check-wrap {
  width: 100%;
  float: left;
  margin-top: 14px;
}
.upload-butto1.margin a {
  text-decoration: none;
}
p.banner-empty {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-top: 30px;
}
.pricing-table table.table-p td {
  border-bottom: 1px solid #dddddd !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-top: 0px !important;
}
.card-banner-heading p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
input.select-search__input {
  padding-right: 35px !important;
}

@media (min-width: 768px) and (max-width: 991px) {
  .existing-banner {
    padding-left: 20px !important;
  }

  /* .check-wrap{
        margin-left: 65px !important;
    } */

  .upload-two-button-box {
    margin-top: 35px !important;
  }
  .card-box {
    margin-left: 20px !important;
    width: 22% !important;
  }

  .upload-next-btn {
    padding-right: 20px !important;
  }

  .back-btn {
    padding-left: 20px !important;
  }

  .existing-banner.second-b .date-rang2 {
    width: 83% !important;
  }

  .ant-row-flex.ant-row-flex-space-between.ant-row-flex-middle {
    padding: 20px !important;
  }

  .scheduler-view {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .resource-view {
    margin-left: 20px !important;
  }

  .reatiler-b.b-head .card-box.responsive {
    width: 94% !important;
  }
}
