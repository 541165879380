.in-header-wrap.ihb {
  margin-top: 107px !important;
  padding: 0px;
}

a.in-header-link.ihb {
  font-size: 20px !important;
}



@media (min-width: 320px) and (max-width: 480px) {
  .admin-inner-container-am{
    position: unset !important;
  }

  .in-header-wrap.ihb {
    margin-top: 70px !important;
  }
}

@media (max-width: 319px) {
  .admin-inner-container-am{
    position: unset !important;
  }

  .in-header-wrap.ihb {
    margin-top: 60px !important;
  }

  a.in-header-link.ihb {
    font-size: 16px;
  }
   
  /* .in-header-link.ihb{
    font-size: 16px !important;
  } */

  .in-header-wrap h1 a.in-header-link{
    font-size: 16px !important;
  }
}