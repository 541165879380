.legend_fieldset{
    border: 1px solid black;
    border-radius: 5px;
    width: 100%;
}

.legend_fieldset .legend_heading{
    /* border: 1px solid black; */
    margin-left: 10px;
    font-size: 14px;
    padding: 0 5px ;
}

.legend_fieldset .legend_drop_down_input_container{
    position: relative;
    /* margin-top: 16px; */
    /* background-color: chartreuse; */
    /* width: 80%; */
    display: flex;
    align-items: center;
    /* padding: 10px; */
    box-sizing: border-box;
    /* border:1px solid black; */
    border-radius: 5px;
}

.legend_fieldset .legend_drop_down_input_container .legend_drop_down_input{
    position: relative;
    all: unset;
    width: 100%;
    /* border:1px solid black; */
    /* border-radius: 5px; */
    /* background-color: chartreuse; */
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    z-index: 1;
    cursor: pointer;
    /* color: #757575; */
}

.legend_fieldset .legend_drop_down_input_container .legend_drop_down_icon{
position: absolute;
right: 0;
/* background-color: crimson; */
}