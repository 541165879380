.user-m-heading h5{
    font-size: 32px !important;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
  color: #000000;
}
.filter-btn {
  background: #F5843E;
  color: #fff;
  padding: 8px 20px !important;
  border-radius: 8px !important;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  border: solid 2px #F5843E;
}
.usr-search-2 select {
  font-size: 14px !important;
  line-height: 18px !important;
  padding: 9px !important;
}
.filter-btn2.left {
  background: #fff;
  color: #F5843E;
  padding: 8px 20px !important;
  border-radius: 8px !important;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  border: solid 2px #F5843E;
}
/* .filter-btn2.left:hover {
  background: rgba(107, 107, 107, 0.4);
  color: #fff;
} */
/* .filter-btn:hover{
  background: rgba(107, 107, 107, 0.4);
} */
table.table-p {
  width: 99%;
  text-align: center;
  border-collapse: collapse;
}
table.table-p th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 14px 8px;
}
table.table-p td {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 14px 8px;
}
thead.heading-tab tr {
  display: flex;
}

thead.heading-tab {
  background: #1A2B57;
}
thead.heading-tab th {
  color: #fff;
  border: 0px !important;
}
.pricing-table {
  width: 100%;
  float: left;
  margin-top: 30px;
}
.usr-m-box {
  width: 100%;
  float: left;
}
tbody.bodyoftable {
  max-height: 50vh !important;
  overflow: auto !important;
  width: 100% !important;
  float: left;
}
thead.heading-tab {
  width: 100%;
  float: left;
  /* display: flex;
  justify-content: space-around; */
}
tbody.bodyoftable tr {
  display: flex !important;
  justify-content: space-around !important;
  width: 100% !important;
  float: left;
}
tbody.bodyoftable td {
  display: flex;
  width: 100% !important;
  justify-content: center !important;
  align-items: center;
}
.usr-m-conatiner {
  max-width: 1170px;
  width: 100%;
  margin: 0px auto;
}
.user-m-heading {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
}
.usr-m-drowp-box {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
}
.usr-m-drowp-box .clddi_input_container {
  display: grid;
}
.usr-m-drowp-box .clddi_div1 {
  margin-left: 0px;
}
.usr-search {
  width: 28%;
}
.usr-search-2 .clddi_container {
  width: 200px !important;
}
.search-btn {
  margin-left: 20px;
  margin-top: 37px;
}
.usr-m-box {
  width: 100%;
  float: left;
}
.usr-m-conatiner {
  max-width: 1170px;
  width: 100%;
  margin: 0px auto;
}
.user-m-heading {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 30px;
}
.usr-m-drowp-box {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
}
.usr-m-drowp-box .clddi_input_container {
  display: grid;
}
.usr-m-drowp-box .clddi_div1 {
  margin-left: 0px;
}
.usr-search {
  width: 28%;
}
.usr-search-2 .clddi_container {
  width: 200px !important;
}
.search-btn {
  margin-left: 20px;
  margin-top: 37px;
}
.ser-box {
  width: 100px;
  display: flex;
  flex-direction: row;
}
ul.filt {
  display: flex;
  flex-direction: row;
  list-style: none !important;
  justify-content: space-between;
  text-align: end;
  align-items: flex-end;
  width: 100%;
}
ul.filt a {
  color: #F5843E;
  font-size: 18px;
}
.ser-box span.material-icons {
  font-size: 26px;
}
ul.filt li {
  margin-left: 6px;
}
.empty-box {
  width: 99.8%;
  float: left;
  border: solid 1px #ccc;
  padding: 30px;
  text-align: center;
}
.empty-box h3 {
  font-size: 18px;
  font-weight: bold;
}


/* change pass */
.fiter-search-box.change-pass {
  width: 100%;
  float: left;
}
.inner-coantainer {
  width: 40%;
  margin: 0px auto;
}
.pass-change-box {
  width: 100%;
  float: left;
}
.pass-input {
  width: 100%;
  float: left;
  margin-top: 20px;
}
.pass-input .li_container label.li_label {
  width: 30%;
  text-align: right !important;
}
.generate-btn {
  width: 100%;
  float: left;
  padding-left: 30%;
  margin-left: 20px;
  margin-top: 20px;
}
.generate-btn {
  width: 100%;
  float: left;
  padding-left: 30%;
  margin-left: 20px;
  margin-top: 20px;
}

.two-pass-btn {
  width: 100%;
  float: left;
  padding: 50px 0px;
}
button.filter-btn.right {
  float: right;
}
.pass-change-box input{
  width: 100% !important;
}
.pass-change-box .li_input_container {
  width: 70% !important;
}
.label-searc{
  font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    color: #666666;
}
.usr-search input.select-search__input {
  height: 39px;
}
.fiter-search-box table.table-p tbody.bodyoftable tr {
  border-left: solid 1px #ccc;
  border-right: solid 1px #ccc;
}
.fiter-search-box table.table-p tbody.bodyoftable tr:nth-child(even) {
  background: #F5F5F5;
}
.pagination-box.marg-usr {
  margin-top: 30px;
}
/* .pagination-box.marg-usr .cont1 {
  width: 60px !important;
} */
.cont1.add-user {
  width: 50%;
  float: right;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
}
.cont1.add-user a {
  border: solid 2px #F5843E;
}
.pass-input label.clddi_label {
  width: 30%;
  text-align: right !important;
}
.pass-input .clddi_input_container .clddi_div1 {
  width: 70% !important;
}
.pass-input .clddi_input_container .clddi_div1 .clddi_container {
  width: 100% !important;
}
/* .pass-input.drop .clddi_input_container .clddi_div1 .clddi_container .clddi_input {
  padding: 15px 10px !important;
} */
.cont1.add-user.b a {
  font-weight: bold;
}
.cont1.add-user.b a {
  background: #F5843E;
  color: #fff;
  text-decoration: none !important;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 16px;
}
.pricing-table thead.heading-tab th {
  display: flex;
  width: 100% !important;
  justify-content: center;
}

.arrowbox {
  display: flex;
}

.face-value-sort-selected {
  padding-left: 5px;
}

.face-value-sort-unselected {
  padding-left: 5px;
  opacity: 0.36;
}

@media (min-width: 768px) and (max-width: 991px) {
  .pricing-table {
    padding: 0px 30px 0px 30px;
}

.date-over input.dapi_input.box-input-date{
  width: 86%;
}

.date-over-promotion input.dapi_input.box-input-date{
  width: 88%;
}

.usr-m-drowp-box {
  padding: 0px 20px;
}

.right-over-box {
  padding-right: 30px;
}

label.check-wrap.checkbox.responsive {
  margin-left: 75px;
}

.check-override1.overridecheck label.check-wrap.checkbox.responsive {
  margin-left: 0px !important;
}

.manage-radio.check-box label.check-wrap.checkbox.responsive {
  margin-left: 0px !important;
}

button.btn-override.width{
  width: unset !important;
}

.btn-box-override{
padding: 50px 30px 50px 0px;
}

.resp-p-new.resp {
  width: 50% !important;
}

}
 /* 50 0 */