.time-box {
    width: 100%;
    float: left;
}
.time-inn {
    width: 100%;
    float: left;
}
.time-left {
    width: 46%;
    float: left;
}
.preview_container.without_icon {
    width: 100%;
    padding-right: 10px !important;
    padding-left: 10px !important;
    border-radius: 5px;
    height: 40px !important;
    line-height: 40px !important;
}
.time-pick-wrap {
    width: 100%;
    float: left;
    border: 1px solid #cccccc;
    border-radius: 5px;
    height: 40px;
}
.react_times_button.time_picker_preview {
    background-color: transparent;
    border-radius: 5px;
    box-shadow: unset !important;
}
.time-left.margin {
    float: right;
}
.time_picker_container.dark {
    background: transparent !important;
}
.time-box .preview_container {
    width: 100%;
    padding-right: 10px !important;
    padding-left: 10px !important;
    border-radius: 5px;
    height: 40px !important;
    line-height: 40px !important;
}
.dark .time_picker_preview .preview_container svg {
    left: unset;
    right: 3px;
    top: 7px;
}
.outside_container.active {
    width: 300px !important;
    position: relative;
    display: flex;
    top: -30px;
}

.MuiInput-underline:before {
    border-bottom: 0px !important;
}
.MuiInput-underline:after {
    border: 0px !important;
}
.time-box .MuiInput-formControl.MuiInputBase-adornedEnd {
    border: solid 1px #ccc;
    border-radius: 4px;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    height: 48px;
}
.MuiPickersToolbar-toolbar {
    background: #1a2b57 !important;
    color: #fff;
}
 h2.MuiTypography-root {
    color: #fff !important;
}
 .MuiPickersClockPointer-pointer{
    background-color: #1a2b57 !important
}
.MuiPickersClock-pin{
    background-color: #1a2b57 !important
}
.MuiPickersClockPointer-noPoint{
    background-color: #1a2b57 !important
}
 .MuiPickersClockPointer-thumb{
    border: 14px solid #1a2b57 !important
}
span.MuiButton-label{
    color: #1a2b57 !important;
}
.time-box span.material-icons.MuiIcon-root {
    color: #F5843E;
}
.time-box .MuiIconButton-root{
    padding: 5px;
}
p.error-message-time{
    margin-top: 5px;
    font-size: 12px;
    color: red;
    text-align: left;
}
.time-box-mian2 label.gpp-date-label.point {
    font-weight: bold;
    padding: 10px 0px;
    color: #666666;
}
.MuiPickersToolbarText-toolbarBtnSelected {
    color: #fff !important;
}