.qlist-main-box {
    margin-bottom: 40px;
    width: 600px;
    border: solid 1px #cccccc;
    height: 140px;
    overflow: auto;
    padding: 10px;
}
.qlist-main-box.box13 .qlist-column h5 {
    color: #fff !important;
}
.qlist-main-box.box13{
    padding: 0px;
}
.qlist-box {
    display: flex;
}
.qlist-column {
    width: 46%;
    color: #666666;
}
.qlist-column-last {
    width: 8%;
    text-align: center;
}
.qlist-column-last-color {
    color: #3a3f45;
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
}
/* .qlist-column-last-color:hover {
    color: #f6853f;
} */
.qlist-box.heading {
    padding: 10px;
    background: #1a2b57;
}
.qlist-main-box-box-2 {
    padding: 10px 0px;
}
.qlist-box.padding-b {
    padding: 10px 5px;
}
.qlist-column h5 {
    font-size: 14px;
    color: #262626;
}
.qlist-main-box-box-2 .qlist-box:nth-child(odd) {
    background: #F5F5F5;
}