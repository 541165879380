.gfv_table {
    border-collapse: collapse;
    width: 100%;
  }
  
    .gfv_table
    .gfv_thead
    .gfv_thead_tr
    .gfv_thead_th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    color: #666666;
  }
  
    .gfv_table
    .gfv_tbody
    .gfv_tbody_tr:nth-child(odd) {
    /* background-color: #ddd; */
  }

    .gfv_table
    .gfv_tbody
    .gfv_tbody_tr
    .gfv_tbody_td1 {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    color: #666666;
  }

    .gfv_table
    .gfv_tbody
    .gfv_tbody_tr
    .gfv_tbody_td2 {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .fc-box {
    width: 360px;
    float: right;
}
.fc-header {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.fc-col-1 {
    width: 50%;
    float: left;
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    color: #666666;
    font-weight: bold;
}
.fc-body-sec {
    width: 100%;
    float: left;
}
.fc-body-row {
    width: 50%;
    float: left;
}
.fc-body {
  width: 100%;
  float: left;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  color: #666666;
  font-weight: bold;
  height: 66px;
  display: flex;
  align-items: center;
}