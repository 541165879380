.box_image{
       width: 100%;
       height: 500px;
       position: relative;
       background: #333;
  }
  .controls_slider{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 30px;
  }
  .slider_contrl{
    width: 100%;
    display: flex;
    margin-right: 30px;
  }
.upload-banner-box2 img{
    max-width:initial !important
}
.reactEasyCrop_Contain{
    max-width:initial !important;
    max-height:initial !important;
    position:unset !important;
    margin: unset !important;
}
.button_new_crop {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.button_new_crop button.upload-button {
    display: flex;
    justify-content: center;
    align-items: center;
}
.btin_reset_new {
    display: flex;
    justify-content: center;
}
.btin_reset_new button {
    background: transparent;
    border: none;
    color: #1971ff;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.video_tag_box {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
}
.file_croping_box{
    width: 100%;
    float: left;
}
.video_scren{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}