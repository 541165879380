.close-msg-p {
    text-align: right;
}
.msge-pop-box-m {
    width: 500px;
    float: left;
}
.msge-pop-box {
    width: 100%;
    float: left;
}
.msge-pop-f-b {
    width: 100%;
    float: left;
    padding-bottom: 20px;
}
.msge-pop-l {
    width: 20%;
    float: left;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
}
.msge-pop-f {
    width: 80%;
    float: left;
    color: #000000;
    font-size: 18px;
    max-height: 104px;
    overflow: auto;
}
.msge-pop-f.text-area {
    padding: 10px;
    background: #F5F5F5;
    border-radius: 8px;
    border: solid 1px #000000;
    height: 100px;
    overflow: auto;
    overflow-wrap: break-word;
}
.msg-c-btn {
    width: 100%;
    float: left;
    text-align: center;
    padding-top: 30px;
}
button.mesge-detailed {
    font-size: 18px;
    color: #000;
    border: 0px;
    background: transparent;
    font-weight: bold;
}
.close-msg-p i {
    cursor: pointer;
}