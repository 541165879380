
.percentage .input-box {
    height: 3em;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* flex-direction: row-reverse; */
}
.percentage .container-input span {
    width: 20px;
    height: 40px;
}
.percentage .container-input input {
    box-sizing: border-box;
    border: 1px solid #cccccc;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    outline: none;
    height: 48px;
    color: #666666;
    vertical-align: middle;
    font-family: 'Poppins', sans-serif;
    padding: 10px;
    width: 65.8%;
    text-align: right;
}
.percentage .container-input label {
    height: 48px;
    width: 40px;
    float: left;
    background: #eee;
    color: #F5843E !important;
    font-size: 20px;
    border: solid 1px #ccc;
    align-items: center;
    justify-content: center;
    display: flex;
}
.percentage  label.border-rd-left {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.percentage input.border-rd-left{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.percentage input.border-rd-right{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.percentage label.border-rd-right {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.percentage .container-input .si_error_text {
    margin-top: 5px;
    font-size: 12px;
    color: red;
    text-align: left;
    width: 83%;
    float: right;
  }
  .percentage .container-input .input-error-start {
    margin-top: 5px;
    font-size: 12px;
    color: red;
    text-align: left;
    width: 83%;
    float: left;
  }