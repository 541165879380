.bc_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  box-sizing: border-box;
  flex-direction: column;
  /* background-color: gold; */
}
.bc_container .bc_header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: cadetblue; */
  width: 100%;
}
.bc_container .bc_header .bc_btn0 {
  all: unset;
  position: absolute;
  right: 0;
  /* height: 100%;
    width: 100%; */
  /* margin-top: 40px; */
 
}
.bc_container .bc_header .bc_btn0{
  background: #F5843E;
  border: 0px;
  color: rgba(255,255,255,1);
  padding: 10px 20px !important;
  border-radius: 8px !important;
  z-index: 1 !important;
  font-size: 16px;
  transition: all 0.5s;
    position: absolute;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
   cursor: pointer;
}

/* .bc_container .bc_header .bc_btn0:hover {
  background: rgba(107, 107, 107, 0.4);
  border: solid 1px #f6853f;
} */

.bc_container .bc_content_container {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bc_container .bc_content_container .bc_btn_container {
  display: flex;
  margin-top: 60px;
}

.bc_container .bc_content_container .bc_btn_container .bc_btn {
  all: unset;
  /* height: 100%;
    width: 100%; */
  /* margin-top: 40px; */
  /* float: right; */
 
}
.bc_container .bc_content_container .bc_btn_container .bc_btn{
  background: #F5843E;
  border: solid 2px #F5843E;
  color: #ffffff;
  padding: 8px 20px !important;
  border-radius: 8px !important;
  font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
   cursor: pointer;
}
.bc_container .bc_content_container .bc_btn_container .bc_btn2 {
  margin: 0 20px;
}

/* .bc_container .bc_content_container .bc_btn_container .bc_btn:hover {
  background: rgba(107, 107, 107, 0.4);
} */

.bc_container .bc_fieldset {
  border: 1px solid #cccccc;
  /* border-radius: 5px; */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.bc_container .bc_fieldset .bc_heading {
  /* border: 1px solid black; */
  margin-left: 10px;
  font-weight: bold;
  padding: 0 5px;
}

.bc_container .bc_fieldset .bc_fieldset_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bc_container .bc_fieldset .bc_fieldset_div2 {
  margin-top: 20px;
}
.bc_btn_container.change-box.margin {
  width: 100%;
  margin-top: 30px;
}
.bc_btn_ch_left {
  width: 50%;
  float: left;
}
.bc_btn_ch_left.right {
  text-align: right;
}
.check-override1 label.checkbox-label {
  font-size: 17px;
}
fieldset.bc_fieldset.inputfeild-set .bc_fieldset_div.bc_fieldset_div1 input#si_input {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
fieldset.bc_fieldset.inputfeild-set .bc_fieldset_div.bc_fieldset_div2 input#si_input {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
fieldset.bc_fieldset.inputfeild-set.m-margin {
  width: 530px;
  margin-top: 20px;
}
.manage-radio {
  width: 100%;
  float: left;
  margin-top: 20px;
}
fieldset.bc_fieldset.inputfeild-set.m-margin label {
  color: #707070 !important;
}
hr.hr-line {
  border-color: #CBCBCB !important;
  border-top: 0px;
}
.manage-radio.check-box {
  margin-top: 0px;
}
.manage-radio.check-box .check-wrap.checkbox {
  margin-top: 0px !important;
}
label.radio-container label {
  color: #707070 !important;
  padding-top: 3px !important;
  float: left;
}
.check-override1.overridecheck {
  width: 300px;
}