.price-box h5{
    font-size: 32px !important;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
  color: #000000;
}
.container-price {
    max-width: 1170px;
    width: 100%;
    margin: 0px auto;
}


.ov-single-b.upload-op .k-dropzone-hint {
    display: none;
}


.ov-single-b.upload-mci .k-dropzone-hint {
    display: none;
}

i.fa.fa-info-circle.icon-info.op {
    /* display: flex; */
    /* position: relative; */
    top: -35px;
    right: -285px;
}
/* Information */
.icon-info {
    position: relative;
    color: #8d9fb4;
  }
  .icon-info::after {
    display: none;
    margin-left: 5px;
    /* margin-top: 3px; */
      content: attr(data-title2);
      top: 30%;
      /* padding: 6px 10px; */
      padding: 6px 15px;
      border: 1px solid #797979;
    position: absolute;
    -webkit-transform: translatey(-50%);
    -moz-transform: translatey(-50%);
    -o-transform: translatey(-50%); 
    transform: translatey(-50%);
    font-size: 12px;
    line-height: 20px;
    width: 210px;
    background: #F0F2F5;
    border-radius: 8px;
    font-family: "inter-regular";
    color: #000;
  }
  .icon-info:hover {
    color: #002f5f;
    cursor: pointer;
  }
  .icon-info:hover::after {
    display:inline-block;
  }
  .app-status .home-card-box.icon-info:after {
    display: none !important;
}
.ov-feild.box-order-input .li_container .li_input_container input {
    width: 100%;
}
.ov-feild.box-order-input .li_container {
    flex-direction: column;
}
.ov-feild.box-order-input .li_container .li_input_container {
    margin-left: 0px;
    width: 100%;
}
.ov-feild.box-order-input{
    padding-top: 0px !important;
}


/* .ov-single-b.upload-mci .k-widget.k-upload {
    width: 55%;
} */

.pricing-box {
    width: 100%;
    float: left;
}
.price-box {
    width: 100%;
    float: left;
    text-align: center;
}
.pricing-table {
    width: 100%;
    float: left;
    margin-top: 30px;
    /* height: 40vh !important; */
    overflow: auto;
}
.pricing-table table.table-p {
    width: 99.8% !important;
    text-align: center;
    border-collapse: collapse;
}
.pricing-table table.table-p th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 14px 8px;
}
.pricing-table table.table-p td {
    border: 1px solid #dddddd;
    text-align: center;
    font-size: 15px;
    padding-left: 14px;
    word-break: break-word;
}
tr.heading-tab {
    background: #1A2B57;
}
tr.heading-tab th {
    color: #fff;
    border: 0px !important;
}
.override-box {
    width: 100%;
    float: left;
}
.override-left {
    width: 40%;
    float: left;
}
.override-left.margin {
    float: right;
}
.override-two {
    float: left;
    width: 100%;
    padding-top: 20px;
}
.overide-year {
    width: 50%;
    float: left;
}
.overide-year .lddi_input_container {
    display: grid !important;
}
.overide-year .lddi_input_container .lddi_div1 {
    margin-left: 0px !important;
}
.overide-year .lddi_input_container .lddi_container {
    width: 100% !important;
}
.overide-year.margin {
    padding-left: 16px;
}
.label-d {
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    color: #666666;
}
.label-d {
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    color: #666666;
}
.ov-single-b {
    width: 100%;
    float: left;
    padding-top: 20px;
}
.ov-single-b .ltai_container {
    display: grid;
}
.ov-single-b .ltai_input_container {
    margin-left: 0px;
}
.ov-single-b textarea.ltai_input {
    width: 100% !important;
    height: 100px !important;
}

.overide-year .li_container {
    display: grid;
}
.overide-year .li_input_container {
    margin-left: 0px !important;
}
.overide-year input.li_input {
    width: 100% !important;
}
.date-over input.dapi_input.box-input-date {
    width: 90.7%;
}
.ov-single-textarea textarea.ltai_input {
    width: 100% !important;
}
.ov-single-textarea {
    width: 100%;
    float: left;
    padding-top: 20px;
}
.ov-single-textarea .ltai_container {
    display: grid;
}
.ov-single-textarea .ltai_input_container {
    margin-left: 0px !important;
}
.right-over-box {
    width: 100%;
    float: left;
}
.ov-feild {
    width: 100%;
    float: left;
    padding-top: 20px;
}
.ov-feild.radio.margin {
    margin-top: 38px;
}

.label-check {
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    color: #666666;
}
.check-override {
    width: 100%;
    float: left;
}
.ov-feild.check-box .check-wrap.checkbox {
    margin-top: 6px;
}
.ov-feild.mid-radio {
    padding-top: 35px;
}
button.btn-override {
    background: #F5843E;
    color: #fff;
    padding: 8px 20px !important;
    border-radius: 8px !important;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    border: solid 2px #F5843E;
    /* text-transform: uppercase; */
    /* width: 40%; */
}
/* button.btn-override:hover{
    background: rgba(107, 107, 107, 0.4);
} */
button.btn-override.width {
    width: 42%;
}
.btn-box-override {
    width: 100%;
    float: left;
    padding: 50px 0px;
}
.btn-previous {
    float: left;
}
.overide-apply {
    float: right;
}
button.btn-override i {
    font-size: 16px !important;
    font-weight: bold;
}
button.btn-override.right {
    float: right;
}
.overide-year .lddi_input_container .lddi_div1 .lddi_container .lddi_input {
    font-weight: bold;
    padding: 15px 10px;
}
.override-box .lddi_input_container .lddi_div1 .lddi_container .lddi_input {
    font-weight: normal !important;
    padding: 15px 10px;
}
.override-two-11 {
    width: 100%;
    float: left;
}
.override-box textarea.ltai_input {
    border-radius: 4px !important;
}
.override-left.mana-inv-cp .overide-year {
    width: 80%;
}
.btn-box-inv-box {
    width: 100%;
    float: left;
    padding-top: 30px;
    text-align: right;
}
button.btn-override.margin {
    margin-left: 20px;
}
.ov-feild.date-over .lddi_input_container {
    display: grid;
}
.ov-feild.date-over .lddi_div1 {
    margin-left: 0px;
    width: 100%;
    float: left;
}
.ov-feild.date-over .lddi_container {
    width: 100% !important;
}
/* thead.thead-all-over {
    position: sticky;
    top: 0px;
    width:100%;
    float:left;
} */
tbody.tbody-over {
    width: 100%;
    float: left;
    max-height: 30vh;
    overflow: auto;
}
tbody.tbody-over tr {
    width: 100% !important;
    float: left;
    display: flex;
    border-left: solid 1px #ccc;
    justify-content: center;
}
thead.thead-all-over {
    width: 100%;
    float: left;
}
tr.heading-tab {
    display: flex;
    justify-content: center;
    align-items: center;
}
tr.heading-tab th {
    width: 100%;
}
tbody.tbody-over td {
    width: 100%;
}
.overide-year.des-amnt {
    width: 100% !important;
}
.pricing-table table.table-p tbody.tbody-over tr {
    border-right: solid 1px #ccc;
}
.pricing-table table.table-p tbody.tbody-over tr:nth-child(even) {
    background: #F5F5F5;
  }
  label.coupon-trm-l {
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    color: #666666;
    width: 100%;
    float: left;
    padding-left: 0px !important;
}
.override-box .lddi_label {
    padding-left: 0px !important;
}
.override-box  label.li_label {
    padding-left: 0px !important;
}
.override-box  .label-d  {
    padding-left: 0px !important;
}
.ov-single-b .li_container .li_input_container .li_input {
    width: 100% !important;
}
.ov-single-b .li_container {
    display: unset !important;
}
.ov-single-b .override-box label.li_label {
    width: 100%;
    float: left;
}
.override-box label.li_label {
    width: 100%;
    float: left;
}
.ov-single-b .li_input_container {
    margin-left: 0px !important;
    width: 100%;
    float: left;
}

.ov-single-textarea.legal {
    padding-top: 0px !important;
}
.ov-feild.date-over .ltai_container {
    flex-direction: column;
}
.ov-feild.date-over .ltai_container label.ltai_label {
    padding-left: 0px !important;
}
.ov-feild.date-over .ltai_container .ltai_input_container {
    margin-left: 0px !important;
}
.ov-feild.date-over .ltai_container textarea.ltai_input {
    width: 100%;
}
.date-over-promotion input.dapi_input.box-input-date {
    width: 90.7%;
}
.unused-allote {
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
}
.override-two.offer-date input.dapi_input.box-input-date {
    width: 84%;
}
.override-112 {
    width: 100%;
    float: left;
}
button.decline-box-new-prev {
    background: #F5843E;
    color: #fff;
    padding: 8px 20px !important;
    border-radius: 8px !important;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    border: solid 2px #F5843E;
    width: 110px;
}

button.decline-box-new-prev-undo {
    background: #F5843E;
    color: #fff;
    padding: 8px 20px !important;
    border-radius: 8px !important;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    border: solid 2px #F5843E;
    width: 140px;
    margin-left: 20px;
}


button.inv-button-b {
    background: #F5843E;
    color: #fff;
    padding: 8px 20px !important;
    border-radius: 8px !important;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    border: solid 2px #F5843E;
    width: 100px;
}
button.inv-button-pause {
    background: #fff;
    color: #000;
    padding: 8px 20px !important;
    border-radius: 8px !important;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    border: solid 2px #F5843E;
    width: 100px;
}

button.button-cancel.can-new {
    background: #fff;
    color: #000;
    padding: 8px 20px !important;
    border-radius: 8px !important;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    border: solid 2px #F5843E;
    /* margin-right: 20px; */
}

.btn-are-you.new-sty button.buttonStyle {
    margin-right: 20px;
}

.end-compai {
    width: 100%;
    float: left;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
}
p.cpn-heading.are-you {
    width: 100%;
    float: left;
    margin-top: 10px;
    margin-bottom: 24px;
}
.btn-are-you.new-sty {
    display: flex;
    width: 100%;
    justify-content: center;
}
/* .ov-feild.mid-radio {
    display: none;
}
.mid-radio {
    display: none;
} */
.overide-year.des-amnt.offer .overide-year {
    width: 100% !important;
}
p.li_error_text {
    margin-top: 5px;
    font-size: 12px;
    color: red;
    text-align: left;
}
.ov-single-b .overide-year {
    width: 100% !important;
}
.reject-box {
    background: rgba(1, 79, 152, 0.25);
    width: 100%;
    float: left;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
}
h6.reject-heading {
    width: 100%;
    font-size: 14px;
    font-family: "inter-bold";
    float: left;
}
.reject-des {
    width: 100%;
    float: left;
    font-size: 12px;
    font-family: "inter-medium";
}

.edit-func {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.upload-hover-button{
    display: block !important;
    bottom: 0px;
    /* color: #fff; */
    font-size: 18px;
    background: #F5843E;
    border: solid 2px #F5843E !important;
    color: rgba(255,255,255,1);
    padding: 6px 10px !important;
    border-radius: 8px !important;
    margin-right: 72px;
    cursor: pointer;
}
.date-and-time-box {
    width: 80%;
    float: left;
}
.date-and-time-box .overide-year {
    width: 60% !important;
    float: left;
}
.mana-inv-cp  .time-box-mian2 {
    width: 47% !important;
}
.override-left.mana-inv-cp .date-and-time-box .overide-year {
    width: 50% !important;
}
.mana-inv-cp > .overide-year > .box-date-picker div {
    width: 100%;
}
.box-date-picker {
    display: flex;
    width: 100%;
}
.mana-inv-cp .override-two.offer-date input.dapi_input.box-input-date {
    width: 100%;
}
.time-box-mian2{
    width: 38% !important;
    float: left;
    margin-left: 9px;
}
.time-box-mian2 .time-left {
    width: 100% !important;
}
.time-box label.gpp-date-label.point {
    width: 100%;
}
.overde-date-and-time-box {
    width: 100%;
    float: left;
}
.overde-date-and-time-box .ov-single-b {
    width: 60%;
}
.overide-new .box-date-picker {
    display: flex;
    width: 100%;
    flex-direction: row;
}
.overide-new input.dapi_input.box-input-date {
    width: 100%;
}
.overide-new .box-date-picker > div {
    width: 100%;
}
.time-box-mian2.over-mar {
    padding-top: 20px;
}
.time-box .MuiInputAdornment-positionEnd {
    background: #eee;
    color: #F5843E !important;
    align-items: center;
    justify-content: center;
    display: flex;
    float: left;
    max-height: 48px;
    height: 46px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: solid 2px #ccc;
}
.overde-date-and-time-box .ov-feild.date-over {
    width: 60%;
}
.override-two .ltai_container {
    flex-direction: column;
}
.override-two .ltai_container label {
    padding-left: 0px;
}
.override-two .ltai_container .ltai_input_container {
    margin-left: 0px;
}
.override-two .ltai_container textarea.ltai_input {
    width: 100%;
}
.ov-single-b .ltai_container label.ltai_label {
    padding-left: 0px;
}
.override-two .ltai_container p.ltai_label {
    margin-bottom: 0px;
    padding: 10px 0px 0px 0px;
}
.ov-single-b .ltai_container p.ltai_label {
    margin-bottom: 0px;
    padding: 10px 0px 0px 0px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .override-left { padding-left: 30px; }
    

    .override-left.mana-inv-cp .overide-year {
        width: 70%;
    }    
}