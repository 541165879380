/* input[type="checkbox" i] {
    background: transparent !important;
} */



.check-wrap {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .check-wrap input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: solid 1px #707070;
    border-radius: 5px;
    box-shadow: 2px 2px 4px #ccc;
  }
  
  /* On mouse-over, add a grey background color */
  .check-wrap:hover input ~ .checkmark1 {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .check-wrap input:checked ~ .checkmark1 {
    background-color: #F5843E;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark1:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .check-wrap input:checked ~ .checkmark1:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .check-wrap .checkmark1:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  label.checkbox-label {
    pointer-events: none;
    float: left;
}