.box_input_container{
    margin-bottom: 16px;
}

.box_input_container .box_input{
    all: unset;
    width: 100%;
    box-sizing: border-box;
    border:1px solid black;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
}