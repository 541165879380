.main-invoices {
    width: 100%;
    float: left;
}
.invoices-container {
    max-width: 1170px;
    width: 100%;
    margin: 0px auto;
}
.in-inner-box {
    width: 100%;
    float: left;
}
.invoice-box {
    width: 100%;
    float: left;
}
.invoice-box table.invoces-table {
    width: 100%;
    float: left;
    border-collapse: collapse;
}
.invoice-box table.invoces-table td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  .invoice-box table.invoces-table thead {
    border: solid 1px #dddddd;
}
.invoice-box table.invoces-table tbody {
  border: solid 1px #dddddd;
}
.invoice-box table.invoces-table tr {
  border-bottom: solid 1px #dddddd;
}
  .invoice-box table.invoces-table th{
    border: 0px solid #dddddd;
    padding: 10px;
    text-align: center;
  }
  .invoice-box table.invoces-table td {
    border: 0px solid #dddddd;
    text-align: center;
    padding: 10px;
  }
  .invoice-box table.invoces-table tr:nth-child(even) {
    background: #F5F5F5;
}
  .invoice-box table.invoces-table  thead{
    background: #1a2b57;
  }
  .invoice-box table.invoces-table  th{
   color: #fff;
  }
  button.invoice-det {
    background: #F5843E;
    border: 0px;
    color: rgba(255,255,255,1);
    padding: 10px 20px !important;
    border-radius: 8px !important;
    z-index: 1 !important;
    font-size: 16px;
    transition: all 0.5s;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}
/* button.invoice-det:hover {
    background: rgba(107, 107, 107, 0.4);
    border: solid 1px #f6853f;
} */
.invoice-box.scrol {
    /* max-height: 760px;
    overflow: auto; */
    padding-bottom: 30px;
    margin-top: 30px;
}
.in-inner-box.heaight{
  max-height: 76vh;
}
.busniness-b32 {
  width: 100%;
  float: left;
}
.invoice-box table.invoces-table tbody {
  max-height: 280px;
  /* display: inline-block; */
  width: 100%;
  float: left;
  overflow: auto;
}
.invoice-box table.invoces-table tr {
  width: 100%;
  float: left;
}
.invoice-box table.invoces-table tr th {
  width: 25%;
  float: left;
  text-align: left;
}
.invoice-box table.invoces-table tr td {
  width: 25%;
  float: left;
  text-align: left;
}