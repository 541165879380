
.cards-box {
    width: 100%;
    float: left;
    padding: 0px !important;
    margin-bottom: 30px;
    border-radius: 9px;
    border: 0px !important;
    background: #fff;
    box-shadow: 3px 5px 10px #707070;
}
.cards-inn-box {
    width: 70px;
    height: 70px;
    float: left;
    border: 0px !important;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 20px;
    /* background: #014f99; */
    border-radius: 4px;
    color: #fff !important;
}
.cards-col-mid {
    width: 77% !important;
    float: right !important;
    padding-left: 10px;
    text-align: left;
}
.cards-btn-get {
    width: 25%;
    float: left;
    text-align: right;
}
.cards-col-mid p {
    font-size: 14px;
    width: 100%;
    float: left;
    margin-bottom: 0px;
    padding-bottom: 5px;
    color: #43003E;
    font-family: 'AvenirNext-Medium';
}
.card-collapse-btn-info {
    font-size: 16px !important;
    cursor: pointer;
    font-family: 'AvenirNext-Bold' !important;
}
.card-collapse {
    font-size: 14px;
    padding-top: 5px;
    width: 78%;
    float: right;
    text-align: left;
}
.cards-btn-get .card-btn-1 {
    font-size: 14px;
    background: #F5843E;
    color: #fff;
    padding: 4px;
    border-radius: 4px;
    border: solid 1px #F5843E;
    font-weight: bold;
}
/* .cards-btn-get .card-btn-1:hover {
    background: #eee;
    color: #f6853f;
    text-decoration: none;
} */
.button-curve {
    width: 100%;
    display: flex;
    align-items: flex-end;
    text-align: right;
    flex-direction: column;
}
.btn-curve-box {
    background: linear-gradient(90deg, rgba(255,115,49,1) 0%, rgba(255,148,97,1) 100%);
    border-top-right-radius: 9px;
    border-bottom-left-radius: 9px;
    display: flex;
    padding: 4px 10px;
    font-size: 16px;
    color: #fff;
    font-family: 'AvenirNext-DemiBold';
}
.btn-curve-box i.material-icons {
    color: #fff;
    font-size: 22px;
    margin-right: 5px;
}
.redeem-card-inn {
    padding: 15px;
    width: 100%;
    float: left;
}
h2.business-name-head {
    font-size: 18px;
    font-family: 'AvenirNext-Bold';
    color: #43003E;
    padding-top: 10px;
}
ul.list-b-redeem {
    padding-left: 16px;
    margin-top: 5px;
    width: 100%;
    float: left;
    margin-bottom: 0px !important;
}
ul.list-b-redeem li {
    color: #43003E;
    font-size: 18px;
    font-family: 'AvenirNext-Regular';
}
.text-des {
    width: 100%;
    float: left;
    text-align: left;
}
.text-des p {
    font-size: 18px;
    color: #43003E;
    font-family: 'AvenirNext-Regular';
    margin-bottom: 18px;
}
