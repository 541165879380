.main-sipner-g{
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
}
.bg-color-1{
    color: var(--primaryColor);
}
.bg-color-2{
    color: var(--portalText);
}
.bg-color-3{
    color: #FF7737;
}
.spinner-grow.bg-color-1 {
    animation: spinner-grow .65s linear infinite !important;
}
.spinner-grow.bg-color-2 {
    animation: spinner-grow .85s linear infinite;
}
.spinner-grow.bg-color-3 {
    animation: spinner-grow 1s linear infinite;
}